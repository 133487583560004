<template>
	<!-- 检验-报验信息 -->
	<div class="mod-config">
		<el-card body-style="padding-bottom: 0;" style="margin-top: -5px;">
			<div class="title">{{ title }}</div>
			<div class="line"></div>
			<el-row style="margin-top: -10px;margin-left: -20px;line-height:30px;width: 103%;">
				<el-row style="margin-top: 10px;">
					<el-col :span="2">
						<div class="search_title">标的物</div>
					</el-col>
					<el-col :span="22" style="display: flex;flex-wrap: wrap;">
						<span v-for="(subject, index) in subjectList" style="margin-right: 20px;">
							<span class="search_bn_border" v-if="subjectNo === subject.displayValue"
								@click="setSubject(subject)">
								{{ subject.displayName }}
							</span>
							<span class="search_bn" v-else-if="subjectNo !== subject.displayValue"
								@click="setSubject(subject)">
								{{ subject.displayName }}
							</span>
						</span>
					</el-col>
				</el-row>
				<el-row style="margin-top: 17px;">
					<el-col :span="2">
						<div class="search_title">专业</div>
					</el-col>
					<el-col :span="22" style="display: flex;flex-wrap: wrap;">
						<span v-for="(maj, index) in majorList" style="margin-right: 20px;">
							<span class="search_bn_border" v-if="major === maj.displayValue" @click="setMajor(maj)">
								{{ maj.displayName }}
							</span>
							<span class="search_bn" v-else-if="major !== maj.displayValue" @click="setMajor(maj)">
								{{ maj.displayName }}
							</span>
						</span>
					</el-col>
				</el-row>
				<div class="line"></div>
				<el-row style="background-color: rgb(243, 247, 247);margin-top: -10px;height: 45px;width: 103%;">
					<el-col :span="11" style="margin-left:15px;margin-top:5px">
						<!-- <el-button v-preventReClick class="btn" type="primary" size="small" icon="el-icon-circle-plus-outline" v-if="showAdd"
					:disabled="updateFlg || addFlg || againFlg" @click="addOrUpdateHandle()">添加前</el-button> -->
						<el-button v-preventReClick class="btn" type="primary" size="small" v-if="showAdd"
							:disabled="updateFlg || addFlg || againFlg" @click="addHandle()">添加</el-button>
						<el-button v-preventReClick class="btn" type="primary" size="small"
							:disabled="updateFlg || addFlg || againFlg" @click="uploadHandle()">导入</el-button>
						<el-button v-preventReClick class="btn" type="primary" size="small"
							:disabled="updateFlg || addFlg || againFlg" @click="downloadHandle()">导出</el-button>
						<el-button v-preventReClick class="btn" type="primary" size="small"
							:disabled="updateFlg || addFlg || againFlg" @click="downloadTemplate()">模板下载</el-button>
						<el-button v-preventReClick :disabled="disabledBatchDelete || updateFlg || addFlg" class="btn"
							type="primary" size="small" @click="deleteBatch()">批量删除</el-button>
					</el-col>
					<el-col :span="12" style="margin-top: 15px;">
						<div style="float: right;margin-right: 15px;vertical-align:middle;">
							<span style="font-size: 16px; font-weight:bold;vertical-align: middle">
								接受 {{ dataTotal.testConclusionNum }}</span>
							<span
								style="font-size: 16px; font-weight:bold;vertical-align: middle; color: rgb(67, 167, 92);">
								不接受 {{ dataTotal.testConclusionRefuseNum }}</span>
							<span
								style="font-size: 16px; font-weight:bold;vertical-align: middle; color: rgb(240, 159, 8);">
								修正后复检 {{ dataTotal.recheckAfterCorrectionNum }}</span>
							<span
								style="font-size: 16px; font-weight:bold;vertical-align: middle; color: rgb(44, 106, 240);">
								有条件接受 {{ dataTotal.testConclusionWhereNum }}</span>
						</div>
					</el-col>
				</el-row>
				<div class="line" style="margin-top: -4px;"></div>
				<el-form ref="form" :model="form" inline label-width="55px" label-position="left">
					<el-row
						style="background-color: rgb(243, 247, 247);margin-top: -10px;margin-left: 0px;height: 45px;width: 103%;">
						<el-col :span="18" style="margin-left:20px">
							<!-- <el-form-item label="标的物" class="selFormItem1">
								<el-select class="selSubjectInput" v-model="subjectNo" placeholder="请选择"
									:disabled="updateFlg || addFlg || againFlg" clearable>
									<el-option v-for="item in $store.state.common.navBarSubjectList"
										:key="item.displayValue" :label="item.displayName" :value="item.displayValue">
									</el-option>
								</el-select>
							</el-form-item>
							<el-form-item label="专业" class="selFormItem">
								<el-select class="selMajorInput" v-model="major" placeholder="请选择"
									:disabled="updateFlg || addFlg || againFlg" clearable>
									<el-option v-for="item in majorList" :key="item.displayValue" :label="item.displayName"
										:value="item.displayValue">
									</el-option>
								</el-select>
							</el-form-item> -->
							<!-- <el-form-item label="序号">
						<el-input class="selItemInput" v-model="recSortCode" :disabled="updateFlg || addFlg || againFlg"
							clearable></el-input>
					</el-form-item> -->
							<el-form-item label="状态" class="selFormItem">
								<el-select class="selMajorInput" v-model="closeState" placeholder="请选择"
									:disabled="updateFlg || addFlg || againFlg" clearable>
									<el-option v-for="item in closeStateList" :key="item.value" :label="item.label"
										:value="item.value">
									</el-option>
								</el-select>
							</el-form-item>
							<el-form-item label="结论" class="selFormItem">
								<el-select class="selSubjectInput" v-model="testConclusion" placeholder="请选择"
									:disabled="updateFlg || addFlg || againFlg" clearable>
									<el-option v-for="item in ConclusionList" :key="item.displayValue"
										:label="item.displayName" :value="item.displayValue">
									</el-option>
								</el-select>
							</el-form-item>
							<el-form-item label="检验项目" class="selFormItem2">
								<el-input class="selSearchInput" v-model="probContext" :disabled="updateFlg || addFlg || againFlg" clearable></el-input>
							</el-form-item>
							<el-form-item label="检验时间" class="selFormItem2" style="margin-top:2px">
								<el-date-picker class="selDateInput" v-model="testDate" type="daterange" range-separator="~"
									start-placeholder="开始日期" end-placeholder="结束日期" clearable value-format="yyyy-MM-dd"
									:disabled="updateFlg || addFlg || againFlg">
								</el-date-picker>
							</el-form-item>
						</el-col>
						<el-col :span="4" class="selBtnGroup">
							<el-button style="margin-top:5px" v-preventReClick class="btn" type="primary" size="small"
								@click="selGetDataList()" :disabled="updateFlg || addFlg || againFlg"
								v-if="showSearch">查询</el-button>
							<el-button v-preventReClick class="rebtn" size="small"
								:disabled="updateFlg || addFlg || againFlg" @click="resetFormData()">重置</el-button>
						</el-col>
					</el-row>
				</el-form>
			</el-row>
		</el-card>
		<div>
			<el-card style="margin-top: -30px; height: 455px;" body-style="height:415px; padding: 0;">
				<el-row style="height: 100%; margin-bottom: 10px;">
					<el-col :span="24" style="height: 100%;">
						<div class="box">
							<el-table height="395" :row-style="selectRowBackground" :row-class-name="tableRowClassName"
								ref="dataListTable" class="dataListTable" :data="dataList" header-align="center"
								header-cell-class-name="dataListTHeader" @selection-change="handleSelectionChange"
								:cell-style="{ padding: '0px' }" style="margin-bottom: 20px;">
								<el-table-column type="selection" width="45" align="center"
									:selectable="selectable"></el-table-column>
								<el-table-column label="NO." width="90" align="center" fixed="left" prop="recSortCode">
									<template slot-scope="scope">
										<span v-if="!getDisabled(scope.row.testId)">{{ scope.row.recSortCode }}</span>
										<el-input class="sp-input" v-if="getDisabled(scope.row.testId)" :disabled="true"
											v-model="scope.row.recSortCode"></el-input>
									</template>
								</el-table-column>
								<!-- <el-table-column prop="subjectName" label="标的物" width="135" align="left" fixed="left">
									<template slot-scope="scope">
										<span style="margin-left: 15px;" v-if="!getDisabled(scope.row.testId)">{{
											scope.row.subjectName
										}}</span>
										<el-input class="sp-input" v-if="getDisabled(scope.row.testId) && againFlg"
											:disabled="true" v-model="scope.row.subjectName"></el-input>
										<el-select class="sp-input" v-model="scope.row.subjectNo" placeholder="请选择"
											v-if="getDisabled(scope.row.testId) && !againFlg" style="width:110px"
											@change="handleChange(scope.row.testId, scope.$index)">
											<el-option v-for="item in $store.state.common.navBarSubjectList"
												:key="item.displayValue" :label="item.displayName"
												:value="item.displayValue">
											</el-option>
										</el-select>
									</template>
								</el-table-column>
								<el-table-column prop="majorName" label="专业" width="105" align="center" fixed="left">
									<template slot-scope="scope">
										<span style="margin-left: 15px;" v-if="!getDisabled(scope.row.testId)">{{
											scope.row.majorName
										}}</span>
										<el-input class="sp-input" v-if="getDisabled(scope.row.testId) && againFlg"
											:disabled="true" v-model="scope.row.majorName"></el-input>
										<el-select class="sp-input" v-model="scope.row.major" placeholder="请选择"
											v-if="getDisabled(scope.row.testId) && !againFlg" style="width:110px"
											@change="handleChange(scope.row.testId, scope.$index)">
											<el-option v-for="item in majorList" :key="item.displayValue"
												:label="item.displayName" :value="item.displayValue">
											</el-option>
										</el-select>
									</template>
								</el-table-column> -->
								<el-table-column prop="probContext" label="检验项目" align="left" width="400" fixed="left"
									show-overflow-tooltip>
									<template slot-scope="scope">
										<span @click="lookInfoHandle(scope.row.testId)"
											v-if="!getDisabled(scope.row.testId)" style="color: #1f30ff;cursor:pointer;">{{
												scope.row.probContext }}</span>
										<el-input class="sp-input" v-if="getDisabled(scope.row.testId)"
											v-model="scope.row.probContext" :disabled="againFlg"></el-input>
									</template>
								</el-table-column>
								<el-table-column prop="sutContent" label="检验意见" width="350" align="left"
									show-overflow-tooltip>
									<template slot-scope="scope">
										<span v-if="!getDisabled(scope.row.testId)">{{ scope.row.sutContent }}</span>
										<el-input type="textarea" class="sp-input1" v-if="getDisabled(scope.row.testId)"
											v-model="scope.row.sutContent"></el-input>
									</template>
								</el-table-column>
								<el-table-column prop="testConclusionName" label="检验结论" align="center" width="120">
									<el-table-column label="1次检验" align="center" width="150px">
										<template slot-scope="scope">
											<span v-if="scope.row.testConclusion == 0 && !getDisabled(scope.row.testId)"
												style="color: #507ce7;">{{
													scope.row.testConclusionName }}</span>
											<span
												v-if="(scope.row.testConclusion == 1 || scope.row.testConclusion == 3) && !getDisabled(scope.row.testId)"
												style="color: #e7b243;">{{ scope.row.testConclusionName }}</span>
											<span v-if="scope.row.testConclusion == 2 && !getDisabled(scope.row.testId)"
												style="color: #e70013;">{{
													scope.row.testConclusionName }}</span>
											<el-select class="sp-input" v-model="scope.row.testConclusion" placeholder="请选择"
												v-if="getDisabled(scope.row.testId)" style="width:125px" clearable
												:disabled="!containCode(scope.row.recSortCode, ' ')">
												<el-option v-for="item in ConclusionList" :key="item.displayValue"
													:label="item.displayName" :value="item.displayValue">
												</el-option>
											</el-select>
										</template>
									</el-table-column>
									<el-table-column label="2次检验" align="center" width="150px">
										<template slot-scope="scope">
											<span v-if="scope.row.testConclusion2 == 0 && !getDisabled(scope.row.testId)"
												style="color: #507ce7;">{{
													scope.row.testConclusionName2 }}</span>
											<span
												v-if="(scope.row.testConclusion2 == 1 || scope.row.testConclusion2 == 3) && !getDisabled(scope.row.testId)"
												style="color: #e7b243;">{{ scope.row.testConclusionName2 }}</span>
											<span v-if="scope.row.testConclusion2 == 2 && !getDisabled(scope.row.testId)"
												style="color: #e70013;">{{
													scope.row.testConclusionName2 }}</span>
											<el-select class="sp-input" v-model="scope.row.testConclusion2"
												placeholder="请选择" v-if="getDisabled(scope.row.testId)" style="width:125px"
												clearable :disabled="!containCode(scope.row.recSortCode, 'A')">
												<el-option v-for="item in ConclusionList" :key="item.displayValue"
													:label="item.displayName" :value="item.displayValue">
												</el-option>
											</el-select>
										</template>
									</el-table-column>
									<el-table-column label="2次以上检验" align="center" width="150px">
										<template slot-scope="scope">
											<span v-if="scope.row.testConclusion3 == 0 && !getDisabled(scope.row.testId)"
												style="color: #507ce7;">{{
													scope.row.testConclusionName3 }}</span>
											<span
												v-if="(scope.row.testConclusion3 == 1 || scope.row.testConclusion3 == 3) && !getDisabled(scope.row.testId)"
												style="color: #e7b243;">{{ scope.row.testConclusionName3 }}</span>
											<span v-if="scope.row.testConclusion3 == 2 && !getDisabled(scope.row.testId)"
												style="color: #e70013;">{{
													scope.row.testConclusionName3 }}</span>
											<el-select class="sp-input" v-model="scope.row.testConclusion3"
												placeholder="请选择" v-if="getDisabled(scope.row.testId)" style="width:125px"
												clearable :disabled="!containCode(scope.row.recSortCode, 'B')">
												<el-option v-for="item in ConclusionList" :key="item.displayValue"
													:label="item.displayName" :value="item.displayValue">
												</el-option>
											</el-select>
										</template>
									</el-table-column>
								</el-table-column>
								<el-table-column prop="testDate" label="检验日期 " width="165" align="center">
									<template slot-scope="scope">
										<span style="margin-left: 15px;" v-if="!getDisabled(scope.row.testId)">{{
											scope.row.testDate
										}}</span>
										<el-date-picker class="sp-input" v-model="scope.row.testDate" type="date"
											value-format="yyyy-MM-dd" placeholder="选择日期" style="width: 140px;"
											v-if="getDisabled(scope.row.testId)">
										</el-date-picker>
									</template>
								</el-table-column>
								<el-table-column prop="testMan" label="检验人" align="center" width="120">
									<template slot-scope="scope">
										<span v-if="!getDisabled(scope.row.testId)">{{ scope.row.testMan }}</span>
										<el-input class="sp-input" v-if="getDisabled(scope.row.testId)"
											v-model="scope.row.testMan"></el-input>
									</template>
								</el-table-column>
								<el-table-column prop="scope.row.closeTime" label="封闭日期" width="165" align="center">
									<template slot-scope="scope">
										<span style="margin-left: 15px;" v-if="!getDisabled(scope.row.testId)">
											{{ scope.row.closeTime | formatDate('yyyy-MM-dd') }}
										</span>
										<span style="margin-left: 15px;" v-if="!getDisabled(scope.row.testId)
											&& scope.row.closeTime == null && scope.row.finalTestConclusion != '2'">
											/
										</span>
										<el-date-picker class="sp-input" v-model="scope.row.closeTime" type="date"
											value-format="yyyy-MM-dd" placeholder="选择日期" style="width: 140px;"
											v-if="getDisabled(scope.row.testId)">
										</el-date-picker>
									</template>
								</el-table-column>
								<el-table-column prop="closeMan" label="封闭人" align="center" width="150"
									show-overflow-tooltip>
									<template slot-scope="scope">
										<span v-if="!getDisabled(scope.row.testId)">{{ scope.row.closeMan }}</span>
										<span v-if="!getDisabled(scope.row.testId)
											&& scope.row.finalTestConclusion != '2' && (scope.row.closeMan == '' || scope.row.closeMan == null)">
											/</span>
										<el-input class="sp-input" v-if="getDisabled(scope.row.testId)"
											v-model="scope.row.closeMan"></el-input>
									</template>
								</el-table-column>
								<el-table-column prop="remark" label="备注" align="center" width="200" show-overflow-tooltip>
									<template slot-scope="scope">
										<span v-if="!getDisabled(scope.row.testId)">{{ scope.row.remark }}</span>
										<el-input class="sp-input" v-if="getDisabled(scope.row.testId)"
											v-model="scope.row.remark"></el-input>
									</template>
								</el-table-column>
								<el-table-column label="操作" width="160" align="left"
									v-if="showDelete || showEdit || showLook" fixed="right">
									<template slot-scope="scope">
										<el-button v-preventReClick type="text" size="mini"
											@click="updateHandle(scope.row.testId)"
											v-if="showEdit && !updateFlg && !addFlg && !againFlg">修改</el-button>
										<!-- <el-button v-preventReClick type="text" size="mini"
										@click="deleteHandle(scope.row.testId)"
										v-if="showDelete && !updateFlg && !addFlg && !againFlg">删除</el-button> -->
										<el-button v-preventReClick type="text" size="mini"
											@click="picAndFileHandle(scope.row.testId)"
											v-if="!updateFlg && !addFlg && !againFlg">照片与文件</el-button>
										<el-button v-preventReClick type="text" size="mini"
											@click="againHandle(scope.row.testId)"
											v-if="showAdd && scope.row.canRetest === '1' && !updateFlg && !addFlg && !againFlg">复检</el-button>
										<!--新增和复检时按钮-->
										<el-button v-preventReClick type="text" size="small" @click="saveTestRecord()"
											v-if="scope.row.testId == undefined && (addFlg || againFlg)">保存</el-button>
										<el-button v-preventReClick type="text" size="small" @click="cancelSaveHandle()"
											v-if="scope.row.testId == undefined && (addFlg || againFlg)">取消</el-button>
										<!--修改时按钮-->
										<el-button v-preventReClick type="text" size="small"
											@click="updateTestRecord(scope.row)"
											v-if="getDisabled(scope.row.testId) && !addFlg && !againFlg">保存</el-button>
										<el-button v-preventReClick type="text" size="small" @click="cancelUpdateHandle()"
											v-if="getDisabled(scope.row.testId) && !addFlg && !againFlg">取消</el-button>
									</template>
								</el-table-column>
							</el-table>
						</div>
						<el-pagination class="my-pagination" @size-change="sizeChangeHandle"
							@current-change="currentChangeHandle" :current-page="pageIndex" :page-sizes="[10, 20, 50, 100]"
							:page-size="pageSize" :total="totalPage" layout="total, sizes, prev, pager, next, jumper"
							:disabled="updateFlg || addFlg || againFlg">
						</el-pagination>
					</el-col>
				</el-row>
			</el-card>
		</div>
		<add-or-update v-if="addOrUpdateVisible" ref="addOrUpdate" @refreshDataList="dataList"></add-or-update>
		<update v-if="updateVisible" ref="update" @refreshDataList="dataList"></update>
		<add-again v-if="againVisible" ref="addAgain" @refreshDataList="dataList"></add-again>
		<audit v-if="auditVisible" ref="audit" @refreshDataList="dataList"></audit>
		<change-conclusion v-if="changeConclusionVisible" ref="changeConclusion"
			@refreshDataList="dataList"></change-conclusion>
		<close-problem v-if="closeProblemVisible" ref="closeProblem" @refreshDataList="getDataList()"></close-problem>
		<cancel-problem v-if="cancelProblemVisible" ref="cancelProblem" @refreshDataList="getDataList()"></cancel-problem>
		<look v-if="lookVisible" ref="look"></look>
		<excel-upload v-if="excelUploadVisible" ref="excelUpload" @refreshDataList="getDataList"></excel-upload>
		<pic-and-file v-if="picAndFileVisible" ref="picAndFile" @refreshDataList="getDataList"></pic-and-file>
		<information-info v-if="lookInfoVisible" ref="informationInfo"></information-info>
	</div>
</template>
<script>
import AddOrUpdate from './information-add-or-update'
import ChangeConclusion from './information-change-conclusion'
import CloseProblem from './../problemOpi/problem-close'
import CancelProblem from './../problemOpi/problem-cancel'
import Update from './information-update'
import AddAgain from './information-again'
import $common from "@/utils/common.js"
import ExcelUpload from './information-excel-upload'
import audit from './information-audit'
import look from './information-look'
import PicAndFile from './information-pic-and-file'
import InformationInfo from "./information-info"
export default {
	data() {
		return {
			showSearch: false,
			showAdd: false,
			closeProblemVisible: false,
			cancelProblemVisible: false,
			showSelect: false,
			showDelete: false,
			showApply: false,
			showAccept: false,
			showConfirm: false,
			showEdit: false,
			showLook: false,
			pageSpan: 12,
			probContext: '',
			tabIndex: "0",
			closeStateList: [{
				label: '未封闭',
				value: '0'
			}, {
				label: '已封闭',
				value: '1'
			}],
			addOrUpdateVisible: false,
			excelUploadVisible: false,
			updateVisible: false,
			againVisible: false,
			auditVisible: false,
			lookVisible: false,
			lookInfoVisible: false,
			changeConclusionVisible: false,
			disabledBatchDelete: true,
			picAndFileVisible: false,
			updateFlg: false,
			addFlg: false,
			againFlg: false,
			tableSelVals: [],
			form: {},
			//当前页码
			pageIndex: 1,
			//当前记录数
			pageSize: 20,
			//总页数
			totalPage: 0,

			selAllFlg: false,
			workNo: $common.getItem("workNo"),
			tableSelVal: [],
			tableSelIdArray: [],
			majorList: [],
			major: "",
			closeState: '',
			subjectNo: "",
			Majorgory: 0,
			recSortCode: '',
			category: "",
			SubjectDropList: [],
			Subject: "",
			dataList: [],
			//结论
			testConclusion: "",
			//
			ConclusionList: [],
			StatusList: [],
			//状态
			state: "",
			testDate: [],
			recNos: [],
			dataTotal: {
				testConclusionNum: 0,
				testConclusionRefuseNum: 0,
				recheckAfterCorrectionNum: 0,
				testConclusionWhereNum: 0
			},
			oldAgainTestId: '',
			title: '',
			subjectList: [],
		};
	},
	components: {
		AddOrUpdate,
		audit,
		look,
		Update,
		AddAgain,
		ChangeConclusion,
		CancelProblem, CloseProblem,
		ExcelUpload,
		PicAndFile,
		InformationInfo
	},
	mounted() {
		this.category = this.$route.query.type;
		if (this.category === '1') {
			this.title = '质量报验'
		} else if (this.category === '2') {
			this.title = '安全报验'
		}
		this.showSearch = $common.isAuth('information:search:' + this.category);
		this.showAdd = $common.isAuth('information:add:' + this.category);
		this.showSelect = $common.isAuth('information:select:' + this.category);
		this.showDelete = $common.isAuth('information:delete:' + this.category);
		this.showApply = $common.isAuth('information:apply:' + this.category);
		this.showAccept = $common.isAuth('information:accept:' + this.category);
		this.showConfirm = $common.isAuth('information:confirm:' + this.category);
		this.showEdit = $common.isAuth('information:edit:' + this.category);
		this.showLook = $common.isAuth('information:look:' + this.category);
		if (!this.showAdd && !this.showSelect && !this.showDelete) {
			this.pageSpan = 24;
		}
		// if (this.showSearch) {
		// 	this.getDataList();
		// }
		this.getSubjectDropDown()
		this.getMajorList();
		this.getInspectionStatus();
		this.getInspectionConclusion();
	},
	filters: {
		formatDate: function (value, args) {
			if (!value) {
				return ''
			}
			var dt = new Date(value);
			if (args == 'yyyy-M-d') {// yyyy-M-d
				let year = dt.getFullYear();
				let month = dt.getMonth() + 1;
				let date = dt.getDate();
				return `${year}-${month}-${date}`;
			} else if (args == 'yyyy-M-d H:m:s') {// yyyy-M-d H:m:s
				let year = dt.getFullYear();
				let month = dt.getMonth() + 1;
				let date = dt.getDate();
				let hour = dt.getHours();
				let minute = dt.getMinutes();
				let second = dt.getSeconds();
				return `${year}-${month}-${date} ${hour}:${minute}:${second}`;
			} else if (args == 'yyyy-MM-dd') {// yyyy-MM-dd
				let year = dt.getFullYear();
				let month = (dt.getMonth() + 1).toString().padStart(2, '0');
				let date = dt.getDate().toString().padStart(2, '0');
				return `${year}-${month}-${date}`;
			} else {// yyyy-MM-dd HH:mm:ss
				let year = dt.getFullYear();
				let month = (dt.getMonth() + 1).toString().padStart(2, '0');
				let date = dt.getDate().toString().padStart(2, '0');
				let hour = dt.getHours().toString().padStart(2, '0');
				let minute = dt.getMinutes().toString().padStart(2, '0');
				let second = dt.getSeconds().toString().padStart(2, '0');
				return `${year}-${month}-${date} ${hour}:${minute}:${second}`;
			}
		}
	},
	methods: {
		getCurrentTime() {
			//获取当前时间并打印
			var _this = this;
			let yy = new Date().getFullYear();
			let mm = new Date().getMonth() + 1;
			let dd = new Date().getDate();
			let hh = new Date().getHours();
			let mf = new Date().getMinutes() < 10 ? '0' + new Date().getMinutes() : new Date().getMinutes();
			let ss = new Date().getSeconds() < 10 ? '0' + new Date().getSeconds() : new Date().getSeconds();
			return yy + '-' + mm + '-' + dd;
		},

		containCode(recSortCode, code) {
			var sortCode = recSortCode + "";
			var numReg = /^[0-9]+$/
			var numRe = new RegExp(numReg)
			if (numRe.test(sortCode) && code == ' ') {
				return true;
			} else if (sortCode.indexOf(code) != -1) {
				return true;
			} else {
				return false;
			}
		},

		isClose(row) {
			return row.problem != null && row.problem.closeTime != null;
		},
		neetClose(row) {
			return row.testConclusion == '2' && row.problem.probId != null && row.problem.closeTime == null;
		},
		handleChange(testId, index) {
			if (testId === undefined) {
				if (this.dataList[0].subjectNo && this.dataList[0].major) {
					this.getNextRecCode("0");
				}
			} else {
				if (this.dataList[index].subjectNo && this.dataList[index].major) {
					this.getNextRecCode(index);
				}
			}
		},
		addHandle() {
			let _url = "/business/testrecord/getLastRecord";
			this.$http({
				url: this.$store.state.httpUrl + _url,
				method: "post",
				data: this.$http.adornData({
					workNo: $common.getItem("workNo"),
					category: this.category,
				})
			}).then(({ data }) => {
				if (data && data.resultCode === 200) {
					// 首行新增一条记录
					let dataList = [];
					var nextRecCode = 0;
					let testRecord = {
						workNo: $common.getItem("workNo"),
						subjectNo: this.subjectNo,
						major: this.major,
						category: this.category,
						recSortCode: nextRecCode,
						probContext: '',
						sutContent: '',
						testConclusion: '',
						testConclusion2: '',
						testConclusion3: '',
						testDate: this.getCurrentTime(),
						testMan: $common.getItem("userName"),
						closeTime: '',
						closeMan: '',
						remark: '',
					}
					dataList.push(testRecord);
					for (var i = 0; i < this.dataList.length; i++) {
						dataList.push(this.dataList[i])
					}
					this.dataList = dataList
					this.addFlg = true;
					this.getNextRecCode(0);
				}
			});
		},
		getNextRecCode(index) {
			let _url = "/business/testrecord/getNextRecCode";
			this.$http({
				url: this.$store.state.httpUrl + _url,
				method: "post",
				data: this.$http.adornData({
					workNo: $common.getItem("workNo"),
					subjectNo: this.dataList[index].subjectNo,
					major: this.dataList[index].major,
					category: this.category,
				})
			}).then(({ data }) => {
				if (data && data.resultCode === 200) {
					this.$set(this.dataList[index], 'recSortCode', data.body)
					if (this.dataList[index].recSortCode === '') {
						this.dataList[index].recSortCode = '1'
					}
				}
			});
		},
		cancelSaveHandle() {
			this.addFlg = false;
			this.againFlg = false;
			this.oldAgainTestId = '';
			let dataList = [];
			for (var i = 0; i < this.dataList.length; i++) {
				if (this.dataList[i].testId != undefined) {
					dataList.push(this.dataList[i])
				}
			}
			this.dataList = dataList
		},
		updateHandle(val) {
			this.recNos.push(val)
			this.updateFlg = true;
		},
		cancelUpdateHandle() {
			this.getDataList()
			this.recNos = [];
			this.updateFlg = false;
		},
		getDisabled(val) {
			if (val == '' || val == undefined) {
				return true;
			} else {
				if (this.recNos.includes(val) && this.updateFlg) {
					return true;
				}
				return false;
			}
		},
		againHandle(testId) {
			this.oldAgainTestId = testId;
			this.$http({
				url: this.$store.state.httpUrl + "/business/testrecord/info/" + testId,
				method: "get",
				params: {},
			}).then(({
				data
			}) => {
				if (data && data.resultCode === 200) {
					let dataList = [];
					let testRecord = {
						workNo: $common.getItem("workNo"),
						subjectNo: data.body.subjectNo,
						subjectName: data.body.subjectName,
						major: data.body.major,
						majorName: data.body.majorName,
						category: this.category,
						recSortCode: data.body.nextSortCode,
						probContext: data.body.probContext,
						sutContent: '',
						testConclusion: '',
						testConclusion2: '',
						testConclusion3: '',
						testDate: data.body.testDate,
						testMan: data.body.testMan,
						closeTime: '',
						closeMan: '',
						remark: '',
						oldAgainTestId: testId
					}
					dataList.push(testRecord);
					for (var i = 0; i < this.dataList.length; i++) {
						dataList.push(this.dataList[i])
					}
					this.dataList = dataList
					this.againFlg = true;
				}
			});
		},
		closeProblemHandle(id) {
			this.closeProblemVisible = true
			this.$nextTick(() => {
				this.$refs.closeProblem.init(id)
			})
		},
		cancelProblemHandle(id) {
			this.cancelProblemVisible = true
			this.$nextTick(() => {
				this.$refs.cancelProblem.init(id)
			})
		},
		selectable(row) {
			return true;  //不禁用
		},
		uploadHandle() {
			this.excelUploadVisible = true;
			this.$nextTick(() => {
				this.$refs.excelUpload.init(this.category)
			})
		},
		downloadHandle() {
			const rLoading = this.openLoading();
			this.$http({
				url: this.$store.state.httpUrl + "/business/testrecord/download",
				method: 'get',
				params: this.$http.adornParams({
					major: this.major,
					subjectNo: this.subjectNo,
					state: this.state,
					testDateBegin: this.testDate[0] ? this.testDate[0] : "",
					testDateEnd: this.testDate[1] ? this.testDate[1] : "",
					testConclusion: this.testConclusion,
					category: this.category,
					probContext: this.probContext,
					closeState: this.closeState,
					workNo: $common.getItem("workNo"),
					recSortCode: this.recSortCode,
				})
			}).then(({ data }) => {
				rLoading.close();
				if (data && data.resultCode === 200) {
					if (this.$store.state.showTestFlag) {
						window.open(data.body)
					} else {
						this.global.downLoadFile(data.body, '报验记录表.xlsx');
					}
				} else {
				}
			})
		},
		downloadTemplate() {
			this.$http({
				url: this.$store.state.httpUrl + "/common/getTemplateUrl?type=5",
				method: 'get',
				params: {}
			}).then(({ data }) => {
				if (data && data.resultCode === 200) {
					if (data.body) {
						// window.open(data.body);
						if (this.$store.state.showTestFlag) {
							window.open(data.body)
						} else {
							this.global.downLoadFile(data.body, '报验记录表模板.xlsx');
						}
					} else {
						this.$message.error("未配置模板，请联系运维管理人员")
					}
				}
			});
		},
		countTestRecord() {
			this.$http({
				url: this.$store.state.httpUrl + "/business/testrecord/countTestRecord",
				method: 'get',
				params: this.$http.adornParams({
					major: this.major,
					subjectNo: this.subjectNo,
					recSortCode: this.recSortCode,
					state: this.state,
					testDateBegin: this.testDate[0] ? this.testDate[0] : "",
					testDateEnd: this.testDate[1] ? this.testDate[1] : "",
					testConclusion: this.testConclusion,
					category: this.category,
					probContext: this.probContext,
					closeState: this.closeState,
					workNo: $common.getItem("workNo"),
				})
			}).then(({ data }) => {
				if (data && data.resultCode === 200) {
					this.dataTotal = data.body
				}
			})
		},
		// 获取数据列表
		getDataList() {
			this.countTestRecord()
			this.$http({
				url: this.$store.state.httpUrl + "/business/testrecord/list",
				method: 'get',
				params: this.$http.adornParams({
					page: this.pageIndex,
					major: this.major,
					subjectNo: this.subjectNo,
					recSortCode: this.recSortCode,
					state: this.state,
					testDateBegin: this.testDate[0] ? this.testDate[0] : "",
					testDateEnd: this.testDate[1] ? this.testDate[1] : "",
					testConclusion: this.testConclusion,
					category: this.category,
					probContext: this.probContext,
					closeState: this.closeState,
					limit: this.pageSize,
					workNo: $common.getItem("workNo"),
				})
			}).then(({
				data
			}) => {
				if (data && data.resultCode === 200) {
					this.dataList = data.body.records;
					this.totalPage = data.body.totalCount;
				} else {
					this.dataList = []
					this.totalPage = 0
				}
			})
		},
		saveTestRecord() {
			if (this.dataList[0].subjectNo == null || this.dataList[0].subjectNo == "") {
				this.$message.error("请选择标的物");
				return;
			}

			if (this.dataList[0].major == null || this.dataList[0].major == "") {
				this.$message.error("请选择专业");
				return;
			}

			if (this.dataList[0].probContext == null || this.dataList[0].probContext == "") {
				this.$message.error("请填写检验项目");
				return;
			}

			if (this.dataList[0].testDate == null || this.dataList[0].testDate == "") {
				this.$message.error("请填写检验时间");
				return;
			}

			if (this.dataList[0].testMan == null || this.dataList[0].testMan == "") {
				this.$message.error("请填写检验人");
				return;
			}

			if (this.dataList[0].testConclusion == '' && this.dataList[0].testConclusion2 == '' && this.dataList[0].testConclusion3 == '') {
				this.$message.error("请填写检验结论");
				return;
			}

			let _url = "/business/testrecord/saveOrUpdate";
			this.$http({
				url: this.$store.state.httpUrl + _url,
				method: "post",
				data: this.$http.adornData(this.dataList[0])
			}).then(({
				data
			}) => {
				if (data && data.resultCode === 200) {
					this.$message({
						message: "操作成功",
						type: "success",
						duration: 1500,
					});
					this.getDataList()
					this.addFlg = false
					this.againFlg = false
				}
			});
		},

		updateTestRecord(val) {
			if (val.subjectNo == null || val.subjectNo == "") {
				this.$message.error("请选择标的物");
				return;
			}

			if (val.major == null || val.major == "") {
				this.$message.error("请选择专业");
				return;
			}

			if (val.probContext == null || val.probContext == "") {
				this.$message.error("请填写检验项目");
				return;
			}

			if (val.testDate == null || val.testDate == "") {
				this.$message.error("请填写检验时间");
				return;
			}

			if (val.testMan == null || val.testMan == "") {
				this.$message.error("请填写检验人");
				return;
			}

			if (val.testConclusion == '' && val.testConclusion2 == '' && val.testConclusion3 == '') {
				this.$message.error("请填写检验结论");
				return;
			}

			let _url = "/business/testrecord/saveOrUpdate";
			this.$http({
				url: this.$store.state.httpUrl + _url,
				method: "post",
				data: this.$http.adornData(val)
			}).then(({
				data
			}) => {
				if (data && data.resultCode === 200) {
					this.$message({
						message: "操作成功",
						type: "success",
						duration: 1500,
					});
					this.getDataList()
					this.updateFlg = false
					this.recNos = [];
				}
			});
		},
		//获取报验结论
		getInspectionConclusion() {
			this.$http({
				url: this.$store.state.httpUrl + "/common/getInspectionConclusion",
				method: "get",
				params: {},
			}).then(({
				data
			}) => {
				if (data && data.resultCode === 200) {
					this.ConclusionList = data.body;
				}
			});
		},
		//获取报验状态
		getInspectionStatus() {
			this.$http({
				url: this.$store.state.httpUrl + "/common/getInspectionStatus",
				method: "get",
				params: {},
			}).then(({
				data
			}) => {
				if (data && data.resultCode === 200) {
					this.StatusList = data.body;
				}
			});
		},
		//获取专业
		getMajorList() {
			this.$http({
				url: this.$store.state.httpUrl + "/common/getMajorByWorkNo/" + $common.getItem("workNo"),
				method: "get",
				params: {},
			}).then(({
				data
			}) => {
				if (data && data.resultCode === 200) {
					this.majorList = data.body;
					if (this.majorList != undefined && this.majorList.length > 0) {
						this.major = this.majorList[0].displayValue
						if (this.showSearch) {
							this.getDataList();
						}
					}
				}
			});
		},
		picAndFileHandle(id) {
			this.picAndFileVisible = true;
			this.$nextTick(() => {
				this.$refs.picAndFile.init(id)
			})
		},
		toggleAllSelection() {
			this.$refs.dataListTable.toggleAllSelection();
		},
		setMajorgory(value) {
			this.subjectNo = value;
			this.pageIndex = 1;
			this.getDataList();
		},
		//获取标的物
		getSubjectDropDown() {
			this.$http({
				url: this.$store.state.httpUrl + "/common/getSubjectDropDown/" + $common.getItem("workNo"),
				method: "get",
				params: {},
			}).then(({
				data
			}) => {
				if (data && data.resultCode === 200) {
					this.subjectList = data.body;
					this.subjectNo = this.subjectList[0].displayValue;
					if (this.subjectList != undefined && this.subjectList.length > 0) {
						this.subjectNo = this.subjectList[0].displayValue
						if (this.showSearch) {
							this.getDataList();
						}
					}
				}
			})
		},
		setMajor(major) {
			if (!this.addFlg && !this.updateFlg) {
				this.major = major.displayValue
				this.getDataList()
			}
		},
		setSubject(subject) {
			if (!this.addFlg && !this.updateFlg) {
				this.subjectNo = subject.displayValue
				this.getDataList()
			}
		},
		siteContentViewHeight() {
			var height = this.$store.state.common.documentClientHeight - 320;
			return {
				height: height + 'px'
			}
		},
		handleSelectionChange(selection) {
			this.tableSelVals = selection;
			if (selection.length > 0) {
				this.disabledBatchDelete = false;
			} else {
				this.disabledBatchDelete = true;
			}
		},
		tableRowClassName({ row }) {
			let color = '';
			for (let item of this.tableSelVal) {
				if (item.testId === row.testId) {
					color = 'table-SelectedRow-bgcolor';
					return color
				}
			}
			return color
		},
		// updateHandle(id) {
		// 	this.updateVisible = true;
		// 	this.$nextTick(() => {
		// 		this.$refs.update.init(id, this.category)
		// 	})
		// },
		changeConclusionHandle(id) {
			this.changeConclusionVisible = true;
			this.$nextTick(() => {
				this.$refs.changeConclusion.init(id)
			})
		},
		lookInfoHandle(id) {
			this.lookInfoVisible = true;
			this.$nextTick(() => {
				this.$refs.informationInfo.init(id)
			})
			// this.$router.push({ name: 'informationInfo', query: { testId: id } });
		},
		// againHandle(id) {
		// 	this.againVisible = true;
		// 	this.$nextTick(() => {
		// 		this.$refs.addAgain.init(id, this.category)
		// 	})
		// },
		addOrUpdateHandle(id) {
			this.addOrUpdateVisible = true;
			this.$nextTick(() => {
				this.$refs.addOrUpdate.init(id, this.category)
			})
		},
		// 申请检验
		applyHandle(id, name) {
			this.$confirm(`确认要进行报验申请吗?`, '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			}).then(() => {
				this.$http({
					url: this.$store.state.httpUrl + "/business/testrecord/updateApply",
					method: 'post',
					data: this.$http.adornData({
						testId: id
					})
				}).then(({
					data
				}) => {
					if (data && data.resultCode === 200) {
						this.$message({
							message: '申请检验成功',
							type: 'success',
							duration: 1500,
							onClose: () => {
								this.getDataList()
							}
						})
					} else {
						this.$message.error(data.msg)
					}
				})
			})
		},
		// 接受检验
		acceptHandle(id, name) {
			this.$confirm(`确认要接受本次报验申请吗?`, '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			}).then(() => {
				this.$http({
					url: this.$store.state.httpUrl + "/business/testrecord/updateApplyReady",
					method: 'post',
					data: this.$http.adornData({
						testId: id
					})
				}).then(({ data }) => {
					if (data && data.resultCode === 200) {
						this.$message({
							message: '接受申请成功',
							type: 'success',
							duration: 1500,
							onClose: () => {
								this.getDataList()
							}
						})
					} else {
						this.$message.error(data.msg)
					}
				})
			})
		},
		// 审核
		auditeHandle(id) {
			this.auditVisible = true;
			this.$nextTick(() => {
				this.$refs.audit.init(id, this.category)
			})
		},
		lookHandle(id) {
			this.lookVisible = true;
			this.$nextTick(() => {
				this.$refs.look.init(id, this.category)
			})
		},
		deleteHandle(id) {
			this.tableSelIdArray = [];
			this.tableSelIdArray.push(id);
			if (this.tableSelIdArray.length === 0) {
				this.$message.error("请选择需要删除的报验项目数据");
			} else {
				this.$confirm(`确定对选中的数据进行删除操作?`, '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					this.$http({
						url: this.$store.state.httpUrl + "/business/testrecord/delete",
						method: 'post',
						data: this.tableSelIdArray,
					}).then(({
						data
					}) => {
						if (data && data.resultCode === 200) {
							this.$message({
								message: '操作成功',
								type: 'success',
								duration: 1500,
								onClose: () => {
									this.getDataList()
								}
							})
						}
					})
				});
			}
		},
		deleteBatch() {
			this.$confirm(`确定对选中的数据进行删除操作？`, '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			}).then(() => {
				let records = this.tableSelVals.map((item) => item.testId);
				this.$http({
					url: this.$store.state.httpUrl + "/business/testrecord/delete",
					method: 'post',
					data: records,
				}).then(({ data }) => {
					if (data && data.resultCode === 200) {
						this.$message({
							message: '操作成功',
							type: 'success',
							duration: 1500,
							onClose: () => {
								this.getDataList()
							}
						})
					}
				});
			});
		},
		resetFormData() {
			// this.subjectNo = ""
			// this.major = "";
			this.conclusion = "";
			this.testDate = [];
			this.testConclusion = "";
			this.state = '';
			this.probContext = '';
			this.closeState = '';
			this.recSortCode = ''
		},
		selGetDataList() {
			this.pageIndex = 1;
			this.getDataList();
		},
		// 每页数
		sizeChangeHandle(val) {
			this.pageSize = val;
			this.pageIndex = 1;
			this.getDataList();
		},
		// 当前页
		currentChangeHandle(val) {
			this.pageIndex = val;
			this.getDataList();
		},
		tableRowClassName({ row, rowIndex }) {
			return $common.tableRowClassName({ row, rowIndex });
		},
		selectRowBackground({ row }) {
			const checkIdList = this.tableSelVals.map((item) => item.testId);
			if (checkIdList.includes(row.testId)) {
				return {
					backgroundColor: "#fae8cb",
				};
			}
		},
	}
};
</script>
<style scoped="scoped">
.selItemText {
	font-size: 14px;
	color: #1c1c1c;
	display: inline-block;
	width: 80px;
}

.warm-row {
	background-color: #f9f9f9 !important;
}

.my-pagination {
	margin-right: 20px !important;
}

::v-deep(.el-table th.el-table__cell) {
	font-size: 15px;
	padding: 2px 0;
}

::v-deep(.el-table .el-table__body-wrapper) {
	z-index: 2
}

.sp-input /deep/ .el-input__inner {
	width: 100% !important;
}

.sp-input1 /deep/ .el-textarea__inner {
     /* border: 0px;  */
	 color: black;
	 /* resize: none; */
	 width: 100%;
	 height: 100%
}

.selSubjectInput /deep/ .el-input__inner {
	width: 120px !important;
	height: 30px !important;
}

.selSearchInput /deep/ .el-input__inner {
	width: 180px !important;
	height: 30px !important;
}

.selDateInput {
	width: 250px !important;
	margin-top: 2px;
	height: 32px;

	/* ::v-deep .el-input__inner {
		height: 33px;
	} */
}

.selMajorInput /deep/ .el-input__inner {
	width: 100px !important;
	height: 30px !important;
}

.selDateInput /deep/ .el-range-separator {
	width: 40px !important;
	margin-top: -8px;
}

.selFormItem::v-deep .el-form-item__label {
	font-size: smaller;
	margin-left: -10px;
	width: 37px !important;
}

.selFormItem1::v-deep .el-form-item__label {
	font-size: smaller;
	width: 50px !important;
	margin-left: -10px;
}

.selFormItem2::v-deep .el-form-item__label {
	font-size: smaller;
	width: 59px !important;
	margin-left: -10px;
	margin-top: -1px
}

.box {
	height: 100%;
	overflow: auto;
}
</style>
