<template>
    <el-dialog :close-on-click-modal="false" :visible.sync="visible" width="72%" class="set_dia" append-to-body="true">
        <iframe :src="tUrl" frameborder="0" style="width: 70vw; height: 94vh;" class="set_ifra"></iframe>
    </el-dialog>
</template>

<script>
import $common from "@/utils/common.js"
export default {
    data() {
        return {
            visible: true,
            tUrl: '',
            fileName: ''
        };
    },
    components: {

    },
    activated() {

    },
    mounted() {
    },
    methods: {
        init(fileName, filePath) {
            this.tUrl = ''
            this.fileName = fileName
            this.$nextTick(() => {
                this.visible = true;
            })
            this.viewFile(filePath)
            
        },
        viewFile(filePath) {
            $common.showLoading()
            $.ajax({
			url: this.$store.state.httpUrl + '/business/file/download?filePath=' + encodeURIComponent(filePath),
			type: 'post',
			dataType: 'blob',
			mimeType: 'text/plain; charset=x-user-defined',
			complete: (response) => {
				let rawLength = response['responseText'].length;
				if (rawLength == 0) {
                    $common.loadingHide()
                    this.$message.error("文件不存在！")
                    return;
				}
				$common.loadingHide()
				let array = new Uint8Array(new ArrayBuffer(rawLength));
				for (let i = 0; i < rawLength; i++) {
					array[i] = response['responseText'].charCodeAt(i) & 0xff;
				}
				this.tUrl = "/pdfjs/web/viewer.html?file=" + $common.getObjectURL(array, filePath);
			}
		});

            // this.tUrl = '/pdfjs/web/viewer.html?file=blob:http://localhost:8081/f3b9e9fc-0b63-49a1-a5d0-83b7ace7a4bd'
            // this.tUrl =tUrl
            // console.log("viewFile start", this.$store.state.httpUrl + '/business/file/download?filePath=' + encodeURIComponent(filePath))
            // // this.showLoading()
            // this.$http({
            //     url: this.$store.state.httpUrl + '/business/file/download?filePath=' + encodeURIComponent(filePath),
            //     type: 'post',
            //     dataType: 'blob',
            //     mimeType: 'text/plain; charset=x-user-defined',
            //     complete: (response) => {
            //         console.log("111")
            //         let rawLength = response['responseText'].length;
            //         console.log("rawLength111111" + rawLength)
            //         if (rawLength == 0) {
            //             V.$notify.error({
            //                 title: 'ERROR',
            //                 message: '文件不存在!'
            //             });
            //             return;
            //         }
            //         console.log(222)
            //         // this.loadingHide()
            //         let array = new Uint8Array(new ArrayBuffer(rawLength));
            //         for (let i = 0; i < rawLength; i++) {
            //             array[i] = response['responseText'].charCodeAt(i) & 0xff;
            //         }
            //         this.url = "/pdfjs/web/viewer.html?file=" +  this.getObjectURL(array, fileUrl);
            //         console.log(333,this.url)
            //         // window.open("/pdfjs/web/viewer.html?file=" + this.getObjectURL(array, fileUrl));
            //     },
            //     fail:(err) => {
            //         console.log(555,err)
            //     }
            // });
        },
    }
}
</script>

<style lang="scss" scoped="scoped">
.set_dia { 
    margin-top:-112px !important;
    height: 1150px !important;
    position: fixed;
    top: 10;
    left: 0;
}
.set_ifra {
    margin-top:-22px;
    margin-left:-20px;
}

.set_dia::v-deep .el-dialog__header {
    height: 30px !important;
}

.set_dia::v-deep .el-dialog__headerbtn {
    top: 0px !important;
}
</style>
