<template>
	<el-dialog
		title="问题取消"
		:close-on-click-modal="false"
		:visible.sync="visible"
		width="600px">
		<el-form :model="dataForm" :rules="dataRule" ref="dataForm" label-width="120px" :inline="true">
			<el-row>
				<el-col :span="24">
					<div style="font-size: 15px; color: #00428e; font-weight: bold;">问题描述及整改意见</div>
				</el-col>
				<el-col :span="24">
					<el-form-item label=" ">
						{{dataForm.sutContent}}
					</el-form-item>
				</el-col>
				<el-col :span="24">
					<el-form-item label="取消原因">
						<el-input class="selItemInput descAreaLg" style="width: 268px !important;" type="textarea" rows="3" v-model="dataForm.cancelRsn" placeholder="请填写"></el-input>
					</el-form-item>
				</el-col>
				<el-col :span="24">
					<el-form-item label="取消人">
						<el-input class="selItemInput" type="input" v-model="dataForm.cancelMan" placeholder="请填写"></el-input>
					</el-form-item>
				</el-col>
				<el-col :span="24">
					<el-form-item label="取消时间">
						<el-date-picker  class="selItemInput"
						  v-model="dataForm.cancelTime"
						  type="date"
						  value-format="yyyy-MM-dd"
						  placeholder="选择日期">
						</el-date-picker>
					</el-form-item>
				</el-col>
			</el-row>
			  <el-row class="formBtnGroup">
				<el-button v-preventReClick class="primaryPlainBtn" type="primary" plain @click="visible = false">取消</el-button>
				<el-button v-preventReClick class="primaryPlainBtn" type="primary" @click="dataFormSubmit()">确定</el-button>
			  </el-row>
		</el-form>
	</el-dialog>
</template>

<script>
	import addImg from "@/assets/images/addImg.png"
	import $common from "@/utils/common.js"
	export default {
	  name: "problem-cancel",
	  data() {
	    return {
			addImg: addImg,
			id: "",
			visible: true,
			form:{},
			dataForm:{},
			dataRule: {

			},
	    };
	  },
	  components: {

	  },
	  activated() {

	  },
	  methods: {
		init(id) {
			this.dataForm.probId = id?id:0;
			if(id){
				this.getDetail();
			}
			this.$nextTick(() => {
				this.visible = true;
			})

		},
		getDetail(){
			this.$http({
			  url: this.$store.state.httpUrl + "/business/probleminfo/info/"+this.dataForm.probId,
			  method: "get",
			  params: {},
			}).then(({ data }) => {
			  if (data && data.resultCode === 200) {
				this.dataForm = data.body;
				  if (!this.dataForm.cancelMan){
					  this.dataForm.cancelMan =  $common.getItem("userName");
				  }
				  if (!this.dataForm.cancelTime) {
					  this.dataForm.cancelTime = this.getCurrentTime();
				  }
			  }
			});
		},
		  getCurrentTime() {
			  //获取当前时间并打印
			  var _this = this;
			  let yy = new Date().getFullYear();
			  let mm = new Date().getMonth()+1;
			  let dd = new Date().getDate();
			  let hh = new Date().getHours();
			  let mf = new Date().getMinutes()<10 ? '0'+new Date().getMinutes() : new Date().getMinutes();
			  let ss = new Date().getSeconds()<10 ? '0'+new Date().getSeconds() : new Date().getSeconds();
			  return yy+'-'+mm+'-'+dd;
		  },
		dataFormSubmit() {
		  this.$refs["dataForm"].validate(valid => {
		    if (valid) {
		      this.$http({
		        url: this.$store.state.httpUrl + "/business/probleminfo/updateCancel",
		        method: "post",
		        data: this.$http.adornData(this.dataForm)
		      }).then(({ data }) => {
		        if (data && data.resultCode === 200) {
		          this.$message({
		            message: "操作成功",
		            type: "success",
		            duration: 1500,
		            onClose: () => {
		              this.visible = false;
		              this.$emit("refreshDataList");
		            }
		          });
		        } else {
		          this.$message.error(data.msg);
		        }
		      });
		    }
		  });
		}
	  }
	};
</script>

<style lang="scss" scoped="scoped">
	.selItemInput.descAreaLg{
		width: 718px !important;
	}
	.addImgBox{
		width: 136px;
		height: 136px;
		display: inline-block;
		vertical-align: middle;
		margin-right: 10px;
		position: relative;
	}
</style>
