<template>
	<el-dialog style="z-index: 999998" :title="'报验记录数据导入'" :close-on-click-modal="false" :visible.sync="visible" width="600px">
		<el-form ref="dataForm" :model="dataForm" :rules="dataRule" inline label-width="120px" label-position="left">
			<el-row>
				<el-col :span="24">
					<el-form-item label="标的物" prop="subjectNo">
						<el-select class="selItemInput" v-model="dataForm.subjectNo" placeholder="请选择">
							<el-option v-for="item in subjectList" :key="item.displayValue"
								:label="item.displayName" :value="item.displayValue">
							</el-option>
						</el-select>
					</el-form-item>
				</el-col>
			</el-row>
			<el-row>
				<el-col :span="24">
					<el-upload class="upload-demo" ref="uploadImg" :action="action" :http-request="uploadImg"
						accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" style="margin-top: 20px;">
						<el-button v-preventReClick size="small" type="primary">点击上传</el-button> 
					</el-upload>
				</el-col>
			</el-row>
		</el-form>
	</el-dialog>
</template>

<script>
import $common from "@/utils/common.js"
export default {
	name: "information-add-or-update",
	data() {
		return {
			visible: true,
			action: '',
			form: {},
			dataForm: {
				workNo: '',
				subjectNo: '',
				category: '',
				dataList: []
			},
			dataRule: {
				subjectNo: [
					{ required: true, message: "请选择标的物", trigger: "change" }
				],
			},
			subjectList: [],
		};
	},
	components: {
	},
	mounted() {
		this.getSubjectDropDown();
	},
	methods: {
		getSubjectDropDown() {
			this.$http({
				url: this.$store.state.httpUrl + "/common/getSubjectDropDown/" + $common.getItem("workNo"),
				method: "get",
				params: {},
			}).then(({ data }) => {
				if (data && data.resultCode === 200) {
					this.subjectList = data.body;
				}
			});
		},
		uploadImg(param) {
			const rLoading = this.openLoading();
			const formData = new FormData();
			formData.append('file', param.file);
			formData.append('workNo', $common.getItem("workNo"))
			formData.append('subjectNo', this.dataForm.subjectNo)
			formData.append('category', this.dataForm.category)
			this.$refs.uploadImg.clearFiles();
			if (!this.dataForm.subjectNo) {
				this.$message.error("标的物未选择")
				this.$refs.uploadImg.clearFiles();
				rLoading.close();
				return;
			}
			this.$http.post(
				this.$store.state.httpUrl + "/business/testrecord/uploadFileList",
				formData, {
				headers: {
					'Content-Type': 'multipart/form-data'
				}
			},
			).then(res => {
				rLoading.close();
				res = res.data
				if (res.resultCode === 200) {
					this.$message({
						message: "操作成功",
						type: "success",
						duration: 1500,
						onClose: () => {
							this.visible = false;
							this.$parent.getDataList();
						}
					});
				}
			})
		},
		init(category) {
			this.dataForm = {
				workNo: $common.getItem("workNo"),
				subjectNo: '',
				major: '',
				category: category,
				dataList: []
			}
			this.$nextTick(() => {
				this.$refs['dataForm'].resetFields()
				this.visible = true;
			})
		},
	}
};
</script>

<style lang="scss" scoped="scoped">
.selItemInput.descAreaLg {
	width: 718px !important;
}

.addImgBox {
	width: 136px;
	height: 136px;
	display: inline-block;
	vertical-align: middle;
	margin-right: 10px;
}

.fondstyle {
	color: #3f649f;
	font-size: 15px;
}
</style>
