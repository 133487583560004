<template>
	<el-dialog
		:title="'查看报验记录'"
		:close-on-click-modal="false"
		:visible.sync="visible"
		width="950px">
		<el-form ref="dataForm" :model="dataForm" inline label-width="80px" label-position="left">
			<el-row>
				<el-col :span="12">
					<el-form-item label="专业">
						<el-select class="selItemInput" v-model="dataForm.major" placeholder="请选择" :disabled="true">
							<el-option v-for="item in majorList" :key="item.displayValue" :label="item.displayName"
									   :value="item.displayValue">
							</el-option>
						</el-select>
					</el-form-item>
				</el-col>
				<el-col :span="12">
					<el-form-item label="检验形式">
						<el-select class="selItemInput" v-model="dataForm.testType" placeholder="请选择" :disabled="true">
							<el-option v-for="item in testTypeList" :key="item.displayValue" :label="item.displayName"
									   :value="item.displayValue">
							</el-option>
						</el-select>
					</el-form-item>
				</el-col>
				<el-col :span="12">
					<el-form-item label="申请时间">
						<el-date-picker class="selItemInput" v-model="dataForm.applyDate" type="date" :disabled="true"
										value-format="yyyy-MM-dd" placeholder="选择日期">
						</el-date-picker>
					</el-form-item>
				</el-col>
				<el-col :span="12">
					<el-form-item label="申请人">
						<el-input class="selItemInput" type="input" v-model="dataForm.applyMan" :disabled="true" placeholder="请填写">
						</el-input>
					</el-form-item>
				</el-col>
				<el-col :span="24">
					<el-form-item label="检验内容">
						<el-input class="selItemInput descAreaLg" type="textarea" rows="3"
								  v-model="dataForm.probContext" placeholder="描述检验内容" :disabled="true"></el-input>
					</el-form-item>
				</el-col>
				<el-col :span="12">
					<el-form-item label="检验时间">
						<el-date-picker class="selItemInput" v-model="dataForm.testDate" type="date"
										value-format="yyyy-MM-dd" placeholder="选择日期" :disabled="true">
						</el-date-picker>
					</el-form-item>
				</el-col>
				<el-col :span="12">
					<el-form-item label="检验结论">
						<el-select class="selItemInput" v-model="dataForm.testConclusion" placeholder="请选择" :disabled="true">
							<el-option v-for="item in conclusionList" :key="item.displayValue" :label="item.displayName"
									   :value="item.displayValue">
							</el-option>
						</el-select>
					</el-form-item>
				</el-col>
				<el-col :span="24">
					<el-form-item label="检验意见">
						<el-input class="selItemInput descAreaLg" type="textarea" rows="3" v-model="dataForm.sutContent" :disabled="true"></el-input>
					</el-form-item>
				</el-col>
				<el-col :span="24">
					<el-form-item label="现场照片">
						<div class="addImgBox" v-for="(item,index) in dataForm.list">
							<el-image
									style="width: 100%; height: 100%"
									:src="item.photoPathUri"
									fit="cover"></el-image>
						</div>
						<div v-if="!dataForm.list || dataForm.list.length===0">
							<span style="color: #e7b243;">暂无现场照片</span>
						</div>
					</el-form-item>
				</el-col>
			</el-row>
			<el-row class="formBtnGroup">
				<el-button v-preventReClick class="primaryPlainBtn" type="primary" plain @click="visible = false">关闭</el-button>
			</el-row>
		</el-form>
	</el-dialog>
</template>

<script>
	import img001 from "@/assets/images/001.jpg"
	import addImg from "@/assets/images/addImg.png"
	import $common from "@/utils/common.js"
	export default {
	  name: "information-audit",
	  data() {
	    return {
			category: '',
			value:"",
			value1:"",
			majorList:[],
			action: "",
			conclusionList:[],
			subjectList:[],
			addImg: addImg,
			img001: img001,
			id: "",
			visible: true,
			form:{},
			dataForm:{
				workNo: $common.getItem("workNo"),
				testId:'',
				name: "0",
				subjectNo:$common.getItem("subjectNo"),
				desc:"",
				major: '',
				photoList:[],
				applyDate: '',
				applyMan: '',
				testConclusion: '',
				testDate: '',
				testMan: '',
				probContext: '',
				//标准
				testReferences: '',
			}
	    };
	  },
	  components: {

	  },
		// mounted() {
		// 	this.getSubjectDropDown();
		// },
	  methods: {

		init(id,category) {
			// this.getPersonMajor();
			// this.getSubjectDropDown();
			this.getConclusion();
			this.dataForm = {
				        workNo: $common.getItem("workNo"),
				        name: "0",
				        subjectNo: $common.getItem("subjectNo"),
						desc:"",
						major: '',
						applyDate: '',
			            photoList:[],
						applyMan: '',
						testConclusion: '',
						testDate: '',
						testMan: '',
						probContext: '',
						//标准
						sutContent: '',
			   }
			this.dataForm.testId = id?id:0;
			this.category = category;
			this.getMajorList();
			if(id){
				this.getDetail();
			}
		    this.$nextTick(() => {
		        this.visible = true;
		    })
		},
		  //标的物
		  // getSubjectDropDown(){
			//   this.$http({
			// 	  url:  this.$store.state.httpUrl + "/common/getSubjectDropDown/" + $common.getItem("workNo"),
			// 	  method: "get",
			// 	  params: {},
			//   }).then(({ data }) => {
			// 	  if (data && data.resultCode === 200) {
			// 		  this.subjectList = data.body;
			// 		  this.subjectNo = this.subjectList[0].displayValue;
			// 		  this.getMajorBySubject();
			// 	  }
			//   });
		  // },

		  getMajorList(){
			  this.$http({
				  url:  this.$store.state.httpUrl + "/common/getMajorByWorkNo/" + $common.getItem("workNo"),
				  method: "get",
				  params: {},
			  }).then(({ data }) => {
				  if (data && data.resultCode === 200) {
					  this.majorList = data.body;
				  }
			  });
		  },
		  getDetail(){
			  this.$http({
				  url: this.$store.state.httpUrl + "/business/testrecord/info/"+this.dataForm.testId,
				  method: "get",
				  params: {},
			  }).then(({ data }) => {
				  if (data && data.resultCode === 200) {
					  this.dataForm = data.body
				  }
			  });
		  },
		  //报验结论
		  getConclusion(){
			  this.$http({
				  url:  this.$store.state.httpUrl + "/common/getInspectionConclusion",
				  method: "get",
				  params: {},
			  }).then(({ data }) => {
				  if (data && data.resultCode === 200) {
					  this.conclusionList = data.body;
				  }
			  });
		  },
		  //图片
		  delImg(index){
			  let _photoList = this.dataForm.photoList;
			  _photoList.splice(index,1);
			  this.dataForm.photoList = _photoList;
		  },
		  uploadImg(param){
			  const formData = new FormData();
			  formData.append('file', param.file);
			  this.$refs.uploadImg.clearFiles();
			  this.$http.post(
					  this.$store.state.httpUrl + "/file/upload/"+$common.getItem("workNo")+"/10/0",
					  formData,
					  {
						  headers: { 'Content-Type': 'multipart/form-data' }
					  },
			  ).then(res => {
				  res = res.data
				  if (res.resultCode == 200){
					  this.dataForm.photoList.push({
						  photoName: res.body.fileName,
						  photoPath: res.body.fileSavePath,
						  fileUri: res.body.fileUri
					  });
				  } else {
					  this.$message.info('文件上传失败')
				  }
			  })
		  }
	  }
	};
</script>

<style lang="scss" scoped="scoped">
	.selItemInput.descAreaLg{
		width: 718px !important;
	}
	.addImgBox{
		width: 136px;
		height: 136px;
		display: inline-block;
		vertical-align: middle;
		margin-right: 10px;
	}
	.fondstyle{
		color: #3f649f;
		font-size: 15px;
	}
</style>
