<template>
	<el-dialog :title="'再次报验记录'" :close-on-click-modal="false" :visible.sync="visible" width="950px">
		<el-form ref="dataForm" :model="dataForm" :rules="dataRule" inline label-width="80px" label-position="left">
			<el-row>
				<el-col :span="12">
					<el-form-item label="标的物" prop="subjectNo">
						<el-select class="selItemInput" v-model="dataForm.subjectNo" placeholder="请选择" :disabled="true">
							<el-option v-for="item in $store.state.common.navBarSubjectList" :key="item.displayValue"
								:label="item.displayName" :value="item.displayValue">
							</el-option>
						</el-select>
					</el-form-item>
				</el-col>
				<el-col :span="12">
					<el-form-item label="专业" prop="major">
						<el-select class="selItemInput" v-model="dataForm.major" placeholder="请选择" :disabled="true">
							<el-option v-for="item in majorList" :key="item.displayValue" :label="item.displayName"
								:value="item.displayValue">
							</el-option>
						</el-select>
					</el-form-item>
				</el-col>
				<el-col :span="12">
					<el-form-item label="检验形式" prop="testType">
						<el-select class="selItemInput" v-model="dataForm.testType" placeholder="请选择" :disabled="true">
							<el-option v-for="item in testTypeList" :key="item.displayValue" :label="item.displayName"
								:value="item.displayValue">
							</el-option>
						</el-select>
					</el-form-item>
				</el-col>
				<el-col :span="12">
					<el-form-item label="部件" prop="partsName">
						<el-input class="selItemInput" v-model="dataForm.partsName" placeholder="请输入"
							:disabled="true"></el-input>
					</el-form-item>
				</el-col>
				<el-col :span="24">
					<el-form-item label="检验内容" prop="probContext">
						<el-input class="selItemInput descAreaLg" type="textarea" rows="3" v-model="dataForm.probContext"
							placeholder="描述检验内容" :disabled="true"></el-input>
					</el-form-item>
				</el-col>
				<el-col :span="12">
					<el-form-item label="序号" prop="nextSortCode">
						<el-input class="selItemInput" v-model="dataForm.nextSortCode" placeholder="请输入"
							:disabled="true"></el-input>
					</el-form-item>
				</el-col>
				<el-col :span="12">
					<el-form-item label="检验结论" prop="testConclusion">
						<el-select class="selItemInput" v-model="dataForm.testConclusion" placeholder="请选择">
							<el-option v-for="item in conclusionList" :key="item.displayValue" :label="item.displayName"
								:value="item.displayValue">
							</el-option>
						</el-select>
					</el-form-item>
				</el-col>
				<el-col :span="12">
					<el-form-item label="检验时间" prop="testDate">
						<el-date-picker class="selItemInput" v-model="dataForm.testDate" type="date"
							value-format="yyyy-MM-dd" placeholder="选择日期">
						</el-date-picker>
					</el-form-item>
				</el-col>
				<el-col :span="12">
					<el-form-item label="检验人" prop="testDate">
						<el-input class="selItemInput" v-model="dataForm.testMan" placeholder="请输入检验人"></el-input>
					</el-form-item>
				</el-col>
				<el-col :span="24">
					<el-form-item label="检验意见" prop="sutContent">
						<el-input class="selItemInput descAreaLg" type="textarea" rows="3"
							v-model="dataForm.sutContent"></el-input>
					</el-form-item>
				</el-col>
				<el-col :span="24">
					<el-form-item label="备注" prop="remark">
						<el-input class="selItemInput descAreaLg" type="textarea" rows="3"
							v-model="dataForm.remark"></el-input>
					</el-form-item>
				</el-col>
				<el-col :span="24">
					<el-form-item label="现场照片">
						<el-upload ref="uploadImg" class="upload-demo" action="action" drag :http-request="uploadImg"
							multiple :show-file-list=false :auto-upload="true">
							<i class="el-icon-upload"></i>
							<div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
						</el-upload>
					</el-form-item>
				</el-col>
				<el-col :span="22" style="margin-left: 120px;">
					<div class="addImgBox" v-for="(item, index) in dataForm.list" style="margin-top: 10px;">
						<el-image style="width: 100%; height: 100%" :src="item.photoPathUri" fit="cover"></el-image>
						<div class="delBox">
							<i class="el-icon-delete" @click="delImg(index)"></i>
						</div>
					</div>
				</el-col>
			</el-row>
			<el-row class="formBtnGroup">
				<el-button v-preventReClick class="primaryPlainBtn" type="primary" plain
					@click="visible = false">取消</el-button>
				<el-button v-preventReClick class="primaryPlainBtn" type="primary" @click="dataFormSubmit()">确定</el-button>
			</el-row>
		</el-form>
	</el-dialog>
</template>

<script>
import img001 from "@/assets/images/001.jpg"
import addImg from "@/assets/images/addImg.png"
import $common from "@/utils/common.js"
export default {
	name: "information-again",
	data() {
		return {
			category: '',
			action: "",
			conclusionList: [],
			subjectList: [],
			majorList: [],
			testTypeList: [],
			importantList: [],
			addImg: addImg,
			visible: true,
			form: {},
			dataForm: {
				workNo: $common.getItem("workNo"),
				testId: '',
				recSortCode: '',
				name: "0",
				subjectNo: "",
				desc: "",
				major: '',
				list: [],
				applyDate: '',
				applyMan: '',
				testConclusion: '',
				testDate: '',
				testMan: '',
				probContext: '',
				testTimes: 1,
				//标准
				testReferences: '',
				category: '',
				oldTestId: '',
				partsName: '',
				insProContents: '',
				iptFlg: '',
				remark: '',
				nextSortCode: ''
			},
			dataRule: {
				nextSortCode: [
					{ required: true, message: "请输入序号", trigger: "change" }
				],
				subjectNo: [
					{ required: true, message: "请选择标的物", trigger: "change" }
				],
				sutContent: [
					{ required: true, message: "请输入检验内容", trigger: "blur" }
				],
				testConclusion: [
					{ required: true, message: "请选择检验结论", trigger: "change" }
				],
				testDate: [
					{ required: true, message: "请选择检验时间", trigger: "blur" }
				],
				probContext: [
					{ required: true, message: "请输入检验内容", trigger: "blur" }
				],
				testType: [
					{ required: true, message: "请选择检验形式", trigger: "change" }
				],
				major: [
					{ required: true, message: "请选择专业", trigger: "change" }
				]
			}
		};
	},
	components: {

	},
	// mounted() {
	// 	this.getSubjectDropDown();
	// },
	methods: {
		getProblemImportant() {
			this.$http({
				url: this.$store.state.httpUrl + "/common/getProblemImportant",
				method: "get",
				params: {},
			}).then(({ data }) => {
				if (data && data.resultCode === 200) {
					this.importantList = data.body;
				}
			});
		},
		getCurrentTime() {
			//获取当前时间并打印
			var _this = this;
			let yy = new Date().getFullYear();
			let mm = new Date().getMonth() + 1;
			let dd = new Date().getDate();
			let hh = new Date().getHours();
			let mf = new Date().getMinutes() < 10 ? '0' + new Date().getMinutes() : new Date().getMinutes();
			let ss = new Date().getSeconds() < 10 ? '0' + new Date().getSeconds() : new Date().getSeconds();
			return yy + '-' + mm + '-' + dd;
		},
		init(id, category) {
			// this.getPersonMajor();
			this.getProblemImportant();
			this.getConclusion();
			this.dataForm = {
				workNo: $common.getItem("workNo"),
				name: "0",
				subjectNo: "",
				desc: "",
				major: '',
				applyDate: '',
				list: [],
				applyMan: '',
				testConclusion: '',
				testDate: '',
				testMan: '',
				probContext: '',
				testTimes: 1,
				//标准
				testReferences: '',
				category: category,
				partsName: '',
				insProContents: '',
				iptFlg: '',
				remark: ''
			};
			this.dataForm.oldTestId = id ? id : 0;
			this.category = category;
			this.dataForm.applyDate = this.getCurrentTime();
			this.dataForm.applyMan = $common.getItem("userName");
			this.dataForm.testMan = $common.getItem("userName");
			this.dataForm.testDate = this.getCurrentTime();
			this.getMajorList();
			this.getTestItemTestType();
			if (id) {
				this.getDetail();
			}
			this.$nextTick(() => {
				this.visible = true;
			})
			this.$refs["dataForm"].resetFields();
		},
		getTestItemTestType() {
			this.$http({
				url: this.$store.state.httpUrl + "/common/getTestItemTestType",
				method: "get",
				params: {},
			}).then(({ data }) => {
				if (data && data.resultCode === 200) {
					this.testTypeList = data.body;
				}
			});
		},
		getMajorList() {
			this.$http({
				url: this.$store.state.httpUrl + "/common/getMajorByWorkNo/" + $common.getItem("workNo"),
				method: "get",
				params: {},
			}).then(({
				data
			}) => {
				if (data && data.resultCode === 200) {
					this.majorList = data.body;
				}
			});
		},
		getDetail() {
			this.$http({
				url: this.$store.state.httpUrl + "/business/testrecord/info/" + this.dataForm.oldTestId,
				method: "get",
				params: {},
			}).then(({
				data
			}) => {
				if (data && data.resultCode === 200) {
					this.dataForm = data.body
					// this.dataForm.major = data.body.major;
					// this.dataForm.testType = data.body.testType;
					// this.dataForm.probContext = data.body.probContext;
					// this.dataForm.subjectNo = data.body.subjectNo;
					// this.dataForm.partsName = data.body.partsName;
					// this.dataForm.nextSortCode = data.body.nextSortCode
				}
			});
		},
		//报验结论
		getConclusion() {
			this.$http({
				url: this.$store.state.httpUrl + "/common/getInspectionConclusion",
				method: "get",
				params: {},
			}).then(({
				data
			}) => {
				if (data && data.resultCode === 200) {
					this.conclusionList = data.body;
				}
			});
		},
		//图片
		delImg(index) {
			let _list = this.dataForm.list;
			_list.splice(index, 1);
			this.dataForm.list = _list;
		},
		uploadImg(param) {
			const formData = new FormData();
			formData.append('file', param.file);
			this.$refs.uploadImg.clearFiles();
			this.$http.post(
				this.$store.state.httpUrl + "/file/upload/" + $common.getItem("workNo") + "/10/0",
				formData, {
				headers: {
					'Content-Type': 'multipart/form-data'
				}
			},
			).then(res => {
				res = res.data
				if (res.resultCode == 200) {
					this.dataForm.list.push({
						photoName: res.body.fileName,
						photoPath: res.body.fileSavePath,
						photoPathUri: res.body.fileUri
					});
				} else {
					this.$message.info('文件上传失败')
				}
			})
		},

		dataFormSubmit() {
			this.dataForm.testId = ''
			this.dataForm.recSortCode = this.dataForm.nextSortCode
			this.$refs["dataForm"].validate(valid => {
				if (valid) {
					let _url = "/business/testrecord/saveOrUpdate";
					this.$http({
						url: this.$store.state.httpUrl + _url,
						method: "post",
						data: this.$http.adornData(this.dataForm)
					}).then(({
						data
					}) => {
						if (data && data.resultCode === 200) {
							this.$message({
								message: "操作成功",
								type: "success",
								duration: 1500,
								onClose: () => {
									this.visible = false;
									this.$parent.getDataList();
								}
							});
						} else {
							this.$message.error(data.msg);
						}
					});
				}
			});
		},

	}
};
</script>

<style lang="scss" scoped="scoped">
.selItemInput.descAreaLg {
	width: 718px !important;
}

.addImgBox {
	width: 136px;
	height: 136px;
	display: inline-block;
	vertical-align: middle;
	margin-right: 10px;
}

.fondstyle {
	color: #3f649f;
	font-size: 15px;
}
</style>
