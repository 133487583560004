<template>
	<el-dialog title="问题封闭" :close-on-click-modal="false" :visible.sync="visible" width="1200px">
		<el-form :model="dataForm" :rules="dataRule" ref="dataForm" label-width="120px" :inline="true">
			<el-row>
				<el-col :span="6">
					<el-form-item label="标的物：">
						{{ dataForm.subjectName }}
					</el-form-item>
				</el-col>
				<el-col :span="6">
					<el-form-item label="问题来源：">
						{{ dataForm.probSourceName }}
					</el-form-item>
				</el-col>
				<el-col :span="6">
					<el-form-item label="专业：">
						{{ dataForm.majorName }}
					</el-form-item>
				</el-col>
			</el-row>
			<el-row>
				<el-col :span="6">
					<el-form-item label="范畴：">
						{{ dataForm.categoryName }}
					</el-form-item>
				</el-col>
				<el-col :span="6">
					<el-form-item label="提出人：">
						{{ dataForm.poseMan }}
					</el-form-item>
				</el-col>
				<el-col :span="6">
					<el-form-item label="提出时间：">
						{{ dataForm.poseTime }}
					</el-form-item>
				</el-col>
				<el-col :span="24">
					<div style="font-size: 15px; color: #00428e; font-weight: bold;">巡检/报验内容</div>
				</el-col>
				<el-col :span="24">
					<el-form-item label=" ">
						{{ dataForm.probContext }}
					</el-form-item>
				</el-col>
				<el-col :span="24">
					<div style="font-size: 15px; color: #00428e; font-weight: bold;">问题描述及整改意见</div>
				</el-col>
				<el-col :span="24">
					<el-form-item label=" ">
						{{ dataForm.sutContent }}
					</el-form-item>
				</el-col>
				<el-col :span="24">
					<div style="font-size: 15px; color: #00428e; font-weight: bold; margin: 5px 0;">整改结果</div>
				</el-col>
				<!--				<el-col :span="8">-->
				<!--					<el-form-item label="封闭人" label-width="80">-->
				<!--						<el-input class="selItemInput" type="input" v-model="dataForm.closeMan" placeholder="请填写"></el-input>-->
				<!--					</el-form-item>-->
				<!--				</el-col>-->
				<el-col :span="8" style="margin-bottom: 5px;">
					<el-form-item label="封闭时间：">
						<el-date-picker class="selItemInput1" v-model="dataForm.closeTime" type="date"
							value-format="yyyy-MM-dd" placeholder="选择日期">
						</el-date-picker>
					</el-form-item>
				</el-col>
				<el-col :span="8" style="margin-bottom: 5px;">
					<el-form-item label="封闭人：">
						<el-input class="selItemInput1" type="input" v-model="dataForm.closeMan"
							placeholder="请填写"></el-input>
					</el-form-item>
				</el-col>
				<el-col :span="24" style="margin-bottom: 5px;">
					<el-form-item label="备注：">
						<el-input class="selItemInput descAreaLg" type="textarea" rows="3" v-model="dataForm.remark"
							placeholder="请填写"></el-input>
					</el-form-item>
				</el-col>
				<el-col :span="12">
					<el-form-item label="相关文件：">
						<div class="addFileBox" v-for="(item, index) in dataForm.fileList">
							<div>
								<span
									style="display: inline-block; width: 200px; white-space: nowrap; overflow: hidden; text-overflow: ellipsis; vertical-align: top;">
									{{ item.fileName }}
								</span>
								<i class="el-icon-delete" @click="delFile(index)"></i>
							</div>
						</div>
						<el-upload class="upload-demo" ref="uploadFile" :action="action" drag :http-request="uploadFile"
							multiple :show-file-list=false :auto-upload="true">
							<i class="el-icon-upload"></i>
							<div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
						</el-upload>
					</el-form-item>
				</el-col>
				<el-col :span="12">
					<el-form-item label="相关照片：">
						<el-upload ref="uploadImg" class="upload-demo" action="action" drag :http-request="uploadImg"
							multiple :show-file-list=false :auto-upload="true" accept="image/jpeg,image/png,image/jpg">
							<i class="el-icon-upload"></i>
							<div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
						</el-upload>
					</el-form-item>
				</el-col>
				<el-col :span="22" style="margin-left: 120px;">
					<div class="addImgBox" v-for="(item, index) in dataForm.afterPhotoList" style="margin-top: 10px;">
						<el-image style="width: 100%; height: 100%" :src="item.fileUri" fit="cover"></el-image>
						<div class="delBox">
							<i class="el-icon-delete" @click="delImg(index)"></i>
						</div>
					</div>
				</el-col>
			</el-row>
			<el-row class="formBtnGroup">
				<el-button v-preventReClick class="primaryPlainBtn" type="primary" plain
					@click="visible = false">取消</el-button>
				<el-button v-preventReClick class="primaryPlainBtn" type="primary" @click="dataFormSubmit()">确定</el-button>
			</el-row>
		</el-form>
	</el-dialog>
</template>

<script>
import addImg from "@/assets/images/addImg.png"
import $common from "@/utils/common.js"
export default {
	name: "problem-add-or-update",
	data() {
		return {
			addImg: addImg,
			id: "",
			visible: true,
			form: {},
			action: "",
			dataForm: {},
			dataRule: {
			},
		};
	},
	components: {

	},
	activated() {

	},
	methods: {
		init(id) {
			this.dataForm.probId = id ? id : 0;
			if (id) {
				this.getDetail();
			}
			this.$nextTick(() => {
				this.visible = true;
			})

		},
		getCurrentTime() {
			//获取当前时间并打印
			var _this = this;
			let yy = new Date().getFullYear();
			let mm = new Date().getMonth() + 1;
			let dd = new Date().getDate();
			let hh = new Date().getHours();
			let mf = new Date().getMinutes() < 10 ? '0' + new Date().getMinutes() : new Date().getMinutes();
			let ss = new Date().getSeconds() < 10 ? '0' + new Date().getSeconds() : new Date().getSeconds();
			return yy + '-' + mm + '-' + dd;
		},
		getDetail() {
			this.$http({
				url: this.$store.state.httpUrl + "/business/probleminfo/info/" + this.dataForm.probId,
				method: "get",
				params: {},
			}).then(({ data }) => {
				if (data && data.resultCode === 200) {
					this.dataForm = data.body;
					if (!this.dataForm.closeMan) {
						this.dataForm.closeMan = $common.getItem("userName");
					}
					if (!this.dataForm.closeTime) {
						this.dataForm.closeTime = this.getCurrentTime();
					}
				}
			});
		},
		delImg(index) {
			let _afterPhotoList = this.dataForm.afterPhotoList;
			_afterPhotoList.splice(index, 1);
			this.dataForm.afterPhotoList = _afterPhotoList;
		},
		delFile(index) {
			let _fileList = this.dataForm.fileList;
			_fileList.splice(index, 1);
			this.dataForm.fileList = _fileList;
		},
		uploadFile(param) {
			const formData = new FormData();
			formData.append('file', param.file);
			this.$refs.uploadFile.clearFiles();
			this.$http.post(
				this.$store.state.httpUrl + "/file/upload/" + $common.getItem("workNo") + "/11/0",
				formData,
				{
					headers: { 'Content-Type': 'multipart/form-data' }
				},
			).then(res => {
				res = res.data
				if (res.resultCode == 200) {
					this.dataForm.fileList.push({
						fileName: res.body.fileName,
						filePath: res.body.fileSavePath,
						fileUri: res.body.fileUri
					});
				} else {
					this.$message.info('文件上传失败')
				}
			})
		},
		uploadImg(param) {
			const formData = new FormData();
			formData.append('file', param.file);
			this.$refs.uploadImg.clearFiles();
			this.$http.post(
				this.$store.state.httpUrl + "/file/upload/" + $common.getItem("workNo") + "/10/0",
				formData,
				{
					headers: { 'Content-Type': 'multipart/form-data' }
				},
			).then(res => {
				res = res.data
				if (res.resultCode == 200) {
					this.dataForm.afterPhotoList.push({
						photoName: res.body.fileName,
						photoPath: res.body.fileSavePath,
						fileUri: res.body.fileUri
					});
				} else {
					this.$message.info('文件上传失败')
				}
			})
		},
		dataFormSubmit() {
			this.$refs["dataForm"].validate(valid => {
				if (valid) {
					this.$http({
						url: this.$store.state.httpUrl + "/business/probleminfo/updateClose",
						method: "post",
						data: this.$http.adornData(this.dataForm)
					}).then(({ data }) => {
						if (data && data.resultCode === 200) {
							this.$message({
								message: "操作成功",
								type: "success",
								duration: 1500,
								onClose: () => {
									this.visible = false;
									this.$emit("refreshDataList");
								}
							});
						} else {
							this.$message.error(data.msg);
						}
					});
				}
			});
		}
	}
};
</script>

<style lang="scss" scoped="scoped">
.selItemInput1 {
	width: 180px !important;
}

.selItemInput.descAreaLg {
	width: 718px !important;
}

.addImgBox {
	width: 136px;
	height: 136px;
	display: inline-block;
	vertical-align: middle;
	margin-right: 10px;
	position: relative;
}
</style>
