<template>
	<el-dialog :title="'调整报验结论'" :close-on-click-modal="false" :visible.sync="visible"
		width="650px">
		<el-form ref="dataForm" :model="dataForm" inline label-width="80px" label-position="left">
			<el-row>
				<el-col :span="24">
					<el-form-item label="检验结论">
						<el-select class="selItemInput" v-model="dataForm.testConclusion" placeholder="请选择">
							<el-option v-for="item in conclusionList" :key="item.displayValue" :label="item.displayName"
								:value="item.displayValue">
							</el-option>
						</el-select>
					</el-form-item>
				</el-col>
			</el-row>
			<el-row class="formBtnGroup">
				<el-button v-preventReClick class="primaryPlainBtn" type="primary" plain @click="visible = false">取消</el-button>
				<el-button v-preventReClick class="primaryPlainBtn" type="primary" @click="dataFormSubmit()">保存</el-button>
			</el-row>
		</el-form>
	</el-dialog>
</template>

<script>
	import $common from "@/utils/common.js"
	export default {
		name: "information-change-conclusion",
		data() {
			return {
				conclusionList: [],
				visible: true,
				form: {},
				dataForm: {
					workNo: $common.getItem("workNo"),
					testId: '',
					name: "0",
					subjectNo: $common.getItem("subjectNo"),
					desc: "",
					major: '',
					list: [],
					applyDate: '',
					applyMan: '',
					testConclusion: '',
					testDate: '',
					testMan: '',
					probContext: '',
					testTimes:1,
					//标准
					testReferences: '',
					category: '',
					oldTestId: ''
				}
			};
		},
		components: {

		},
		methods: {
			getCurrentTime() {
				//获取当前时间并打印
				var _this = this;
				let yy = new Date().getFullYear();
				let mm = new Date().getMonth()+1;
				let dd = new Date().getDate();
				let hh = new Date().getHours();
				let mf = new Date().getMinutes()<10 ? '0'+new Date().getMinutes() : new Date().getMinutes();
				let ss = new Date().getSeconds()<10 ? '0'+new Date().getSeconds() : new Date().getSeconds();
				return yy+'-'+mm+'-'+dd;
			},
			init(id) {
				this.getConclusion();
				this.dataForm = {
					workNo: $common.getItem("workNo"),
					name: "0",
					subjectNo: $common.getItem("subjectNo"),
					desc: "",
					major: '',
					applyDate: '',
					list: [],
					applyMan: '',
					testConclusion: '',
					testDate: '',
					testMan: '',
					probContext: '',
					testTimes:1,
					//标准
					testReferences: ''
				};
				this.dataForm.testId = id ? id : 0;
				if (id) {
					this.getDetail();
				}
				this.$nextTick(() => {
					this.visible = true;
				})
			},
			getDetail() {
				this.$http({
					url: this.$store.state.httpUrl + "/business/testrecord/info/" + this.dataForm.testId,
					method: "get",
					params: {},
				}).then(({
					data
				}) => {
					if (data && data.resultCode === 200) {
						this.dataForm = data.body;
					}
				});
			},
			//报验结论
			getConclusion() {
				this.$http({
					url: this.$store.state.httpUrl + "/common/getInspectionConclusion",
					method: "get",
					params: {},
				}).then(({
					data
				}) => {
					if (data && data.resultCode === 200) {
						this.conclusionList = data.body;
					}
				});
			},
			dataFormSubmit() {
				if ("1" === this.dataForm.againFlag) {
					this.$message.error("因已生成再次报验记录，无法修改检验结论");
				} else {
					this.$refs["dataForm"].validate(valid => {
						if (valid) {
							let _url = "/business/testrecord/updateTestConclusion";
							// if (this.dataForm.testId && this.dataForm.testId != 0) {
							// 	_url = "/business/testrecord/update";
							// }
							this.$http({
								url: this.$store.state.httpUrl + _url,
								method: "post",
								data: this.$http.adornData(this.dataForm)
							}).then(({
										 data
									 }) => {
								if (data && data.resultCode === 200) {
									this.$message({
										message: "操作成功",
										type: "success",
										duration: 1500,
										onClose: () => {
											this.visible = false;
											this.$parent.getDataList();
										}
									});
								} else {
									this.$message.error(data.msg);
								}
							});
						}
					});
				}
			},

		}
	};
</script>

<style lang="scss" scoped="scoped">
	.selItemInput.descAreaLg {
		width: 718px !important;
	}

	.addImgBox {
		width: 136px;
		height: 136px;
		display: inline-block;
		vertical-align: middle;
		margin-right: 10px;
	}

	.fondstyle {
		color: #3f649f;
		font-size: 15px;
	}
</style>
