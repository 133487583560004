<template>
    <el-dialog :title="title" :close-on-click-modal="false" :visible.sync="visible" width="1000px">
        <el-form :model="dataForm" :rules="dataRule" ref="dataForm" label-width="115px" :inline="true" class="border-form"
            style="margin-right: 25px;">
            <el-row class="set_border">
                <el-col :span="12" class="col-set_border">
                    <el-form-item label="标的物" class="item">
                        {{ dataForm.subjectName }}
                    </el-form-item>
                </el-col>
                <!-- <el-col :span="8" class="set_border">
                    <el-form-item label="范畴" class="item">
                        {{ dataForm.categoryName }}
                    </el-form-item>
                </el-col> -->
                <el-col :span="12">
                    <el-form-item label="专业" class="item">
                        {{ dataForm.majorName }}
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row class="set_border">
                <el-col :span="12" class="col-set_border">
                    <el-form-item label="检验人" class="item">
                        {{ dataForm.testMan }}
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="检验时间" class="item">
                        {{ dataForm.testDate | formatDate('yyyy-MM-dd') }}
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row class="set_border">
                <el-col :span="6" class="col-set_border">
                    <el-form-item label="检验结论" class="item">
                    </el-form-item>
                </el-col>
                <el-col :span="6" class="col-set_border">
                    <el-form-item label="一次检验" class="item">
                        <span>{{ dataForm.testConclusionName }}</span>
                    </el-form-item>
                </el-col>
                <el-col :span="6" class="col-set_border">
                    <el-form-item label="2次检验" class="item">
                        <span>{{ dataForm.testConclusionName2 }}</span>
                    </el-form-item>
                </el-col>
                <el-col :span="6">
                    <el-form-item label="2次以上检验" class="item">
                        <span>{{ dataForm.testConclusionName3 }}</span>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row class="set_border">
                <el-col :span="24">
                    <el-form-item label="检验项目" class="item">
                        {{ dataForm.probContext }}
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row class="set_border">
                <el-col :span="24">
                    <el-form-item label="检验意见" class="item">
                        {{ dataForm.sutContent }}
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row class="set_border">
                <el-col :span="12" class="col-set_border">
                    <el-form-item label="封闭人" class="item">
                        {{ dataForm.closeMan }}
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="封闭时间" class="item">
                        {{ dataForm.closeTime | formatDate('yyyy-MM-dd') }}
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row class="set_border">
                <el-col :span="24">
                    <el-form-item label="备注" class="item">
                        {{ dataForm.remark }}
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row class="row-form-item">
                <el-col :span="24">
                    <el-form-item label="整改前照片" class="fileItem">
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row class="row-form-item">
                <el-col :span="24">
                    <el-col :span="8">
                        <el-upload ref="uploadImg" class="upload-demo" action="action" drag :http-request="uploadImg"
                            multiple :show-file-list=false :auto-upload="true" accept="image/jpeg,image/png,image/jpg"
                            :data="{ 'type': '0' }">
                            <i class="el-icon-upload"></i>
                            <div class="el-upload__text">将图片拖到此处，或<em>点击上传</em></div>
                        </el-upload>
                    </el-col>
                    <el-col :span="16">
                        <div class="addImgBox" v-for="(item, index) in dataForm.scenePhotoList"
                            style="margin-bottom: 10px;">
                            <el-image style="width: 100%; height: 100%" :src="item.fileUri" fit="cover"></el-image>
                            <div class="delBox">
                                <i class="el-icon-delete" @click="delScenePhoto(index)"></i>
                            </div>
                        </div>
                    </el-col>
                </el-col>
            </el-row>
            <el-row class="row-form-item">
                <el-col :span="24">
                    <el-form-item label="整改后照片" class="fileItem">
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row>
                <el-col :span="8">
                    <el-upload ref="uploadImg" class="upload-demo" action="action" drag :http-request="uploadImg" multiple
                        :show-file-list=false :auto-upload="true" accept="image/jpeg,image/png,image/jpg"
                        :data="{ 'type': '1' }">
                        <i class="el-icon-upload"></i>
                        <div class="el-upload__text">将图片拖到此处，或<em>点击上传</em></div>
                    </el-upload>
                </el-col>
                <el-col :span="16">
                    <div class="addImgBox" v-for="(item, index) in dataForm.closePhotoList" style="margin-bottom: 10px;">
                        <el-image style="width: 100%; height: 100%" :src="item.fileUri" fit="cover"></el-image>
                        <div class="delBox">
                            <i class="el-icon-delete" @click="delClosePhoto(index)"></i>
                        </div>
                    </div>
                </el-col>
            </el-row>
            <el-row class="row-form-item">
                <el-col :span="24">
                    <el-form-item label="封闭文件" class="fileItem">
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row class="row-form-item">
                <el-col :span="8">
                    <el-upload ref="uploadFile" class="upload-demo" action="action" drag :http-request="uploadFile" multiple
                        :show-file-list=false :auto-upload="true">
                        <i class="el-icon-upload"></i>
                        <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
                    </el-upload>
                </el-col>
                <el-col :span="16">
                    <el-table :data="dataForm.closeFileList" :cell-style="{ padding: '0px' }" style="margin-bottom: 20px;"
                        :show-header="false" v-if="dataForm.closeFileList && dataForm.closeFileList.length > 0">
                        <el-table-column width="100" align="center" type="index">
                        </el-table-column>
                        <el-table-column prop="fileName" width="350" align="center">
                            <template slot-scope="scope">
                                <el-input class="sp-input" v-model="scope.row.fileName"></el-input>
                            </template>
                        </el-table-column>
                        <el-table-column width="150" align="center">
                            <template slot-scope="scope">
                                <el-button v-preventReClick type="text" size="small"
                                    @click="selectFile(scope.row.fileUri, scope.row.fileName)">查看</el-button>
                                <el-button v-preventReClick type="text" size="small"
                                    @click="delCloseFile(scope.$index)">删除</el-button>
                            </template>
                        </el-table-column>
                    </el-table>
                </el-col>
            </el-row>
            <el-row class="formBtnGroup">
                <el-button v-preventReClick class="btn" type="primary" size="small" @click="dataFormSubmit()">确定</el-button>
                <el-button v-preventReClick class="btn" type="primary" size="small" @click="visible = false">取消</el-button>
            </el-row>
        </el-form>
    </el-dialog>
</template>

<script>
import addImg from "@/assets/images/addImg.png"
import $common from "@/utils/common.js"
export default {
    name: "patrolList-Info",
    data() {
        return {
            title: '照片与文件',
            addImg: addImg,
            categoryList: [],
            sutConclusionList: [],
            testId: '',
            visible: true,
            action: "",
            form: {},
            probCheck: '0',
            dataForm: {
                workNo: $common.getItem("workNo"),
                subjectNo: "",
                inspectionId: "",
                category: '',
                major: "",
                inspectionTime: "",
                inspectionPlace: "",
                inspectionMan: '',
                inspectionContent: '',
                photo: [],
                sutContent: '',
                scenePhotoList: [],
                closeFileList: [],
                closePhotoList: [],
                inspectionId: ''
            },
            dataRule: {
            }
        };
    },
    components: {

    },
    mounted() {
    },
    filters: {
        formatDate: function (value, args) {
            if (!value) {
                return ''
            }
            var dt = new Date(value);
            if (args == 'yyyy-M-d') {// yyyy-M-d
                let year = dt.getFullYear();
                let month = dt.getMonth() + 1;
                let date = dt.getDate();
                return `${year}-${month}-${date}`;
            } else if (args == 'yyyy-M-d H:m:s') {// yyyy-M-d H:m:s
                let year = dt.getFullYear();
                let month = dt.getMonth() + 1;
                let date = dt.getDate();
                let hour = dt.getHours();
                let minute = dt.getMinutes();
                let second = dt.getSeconds();
                return `${year}-${month}-${date} ${hour}:${minute}:${second}`;
            } else if (args == 'yyyy-MM-dd') {// yyyy-MM-dd
                let year = dt.getFullYear();
                let month = (dt.getMonth() + 1).toString().padStart(2, '0');
                let date = dt.getDate().toString().padStart(2, '0');
                return `${year}-${month}-${date}`;
            } else {// yyyy-MM-dd HH:mm:ss
                let year = dt.getFullYear();
                let month = (dt.getMonth() + 1).toString().padStart(2, '0');
                let date = dt.getDate().toString().padStart(2, '0');
                let hour = dt.getHours().toString().padStart(2, '0');
                let minute = dt.getMinutes().toString().padStart(2, '0');
                let second = dt.getSeconds().toString().padStart(2, '0');
                return `${year}-${month}-${date} ${hour}:${minute}:${second}`;
            }
        }
    },
    methods: {
        goBack() {
            this.$router.back()
        },
        init(id) {
            this.dataForm = {
                scenePhotoList: [],
                closeFileList: [],
                closePhotoList: [],
                inspectionId: this.inspectionId
            }
            this.getCategoryList();
            this.testId = id ? id : '';
            this.getDetail();
            this.$nextTick(() => {
                this.visible = true;
            });

        },
        selectFile(url, filename) {
            filename = this.getSuffix(url, filename)
            this.getBlob(url, (blob) => {
                this.saveAs(blob, filename)
            })
        },
        getSuffix(url, filename) {
            let lastIndex = url.lastIndexOf(".");
            return filename + url.substr(lastIndex)
        },
        getBlob(url, cb) {
            var xhr = new XMLHttpRequest()
            xhr.open('GET', url, true)
            xhr.responseType = 'blob'
            xhr.onload = function () {
                if (xhr.status === 200) {
                    cb(xhr.response)
                }
            }
            xhr.send()
        },
        saveAs(blob, filename) {
            if (window.navigator.msSaveOrOpenBlob) {
                navigator.msSaveBlob(blob, filename)
            }
            else {
                var link = document.createElement('a')
                var body = document.querySelector('body')

                link.href = window.URL.createObjectURL(blob)
                link.download = filename

                link.style.display = 'none'
                body.appendChild(link)

                link.click()
                body.removeChild(link)
                window.URL.revokeObjectURL(link.href)
            }
        },
        getDetail() {
            this.$http({
                url: this.$store.state.httpUrl + "/business/testrecord/info/" + this.testId,
                method: "get",
                params: {},
            }).then(({ data }) => {
                if (data && data.resultCode === 200) {
                    this.dataForm = data.body;
                    this.title = "照片与文件" + " " + this.dataForm.recSortCode
                }
            });
        },
        getCategoryList() {
            this.$http({
                url: this.$store.state.httpUrl + "/common/getCategoryList",
                method: "get",
                params: {},
            }).then(({ data }) => {
                if (data && data.resultCode === 200) {
                    this.categoryList = data.body;
                }
            });
        },
        getCategoryName(categoryId) {
            this.categoryList.forEach((item) => {
                if (categoryId === item.displayValue) {
                    return item.displayName
                }
            })
        },
        // 删除现场照片
        delScenePhoto(index) {
            let _scenePhotoList = this.dataForm.scenePhotoList;
            _scenePhotoList.splice(index, 1);
            this.dataForm.scenePhotoList = _scenePhotoList;
        },
        // 删除封闭相关文件
        delCloseFile(index) {
            let _closeFileList = this.dataForm.closeFileList;
            _closeFileList.splice(index, 1);
            this.dataForm.closeFileList = _closeFileList;
        },
        // 删除封闭相关图片
        delClosePhoto(index) {
            let _closePhotoList = this.dataForm.closePhotoList;
            _closePhotoList.splice(index, 1);
            this.dataForm.closePhotoList = _closePhotoList;
        },
        // 上传照片
        uploadImg(param) {
            let type = param.data.type;
            const formData = new FormData();
            formData.append('file', param.file);
            this.$refs.uploadImg.clearFiles();
            this.$http.post(
                this.$store.state.httpUrl + "/file/upload/" + $common.getItem("workNo") + "/10/0",
                formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            },
            ).then(res => {
                res = res.data
                if (res.resultCode == 200) {
                    if (type == '0') {
                        this.dataForm.scenePhotoList.push({
                            testId: this.testId,
                            recFileType: '0',
                            fileName: res.body.fileName,
                            filePath: res.body.fileSavePath,
                            fileUri: res.body.fileUri
                        });
                    } else if (type == '1') {
                        this.dataForm.closePhotoList.push({
                            testId: this.testId,
                            recFileType: '1',
                            fileName: res.body.fileName,
                            filePath: res.body.fileSavePath,
                            fileUri: res.body.fileUri
                        });
                    }
                } else {
                    this.$message.info('文件上传失败')
                }
            })
        },
        // 上传文件
        uploadFile(param) {
            const formData = new FormData();
            formData.append('file', param.file);
            this.$refs.uploadFile.clearFiles();
            this.$http.post(
                this.$store.state.httpUrl + "/file/upload/" + $common.getItem("workNo") + "/10/0",
                formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            },
            ).then(res => {
                res = res.data
                if (res.resultCode == 200) {
                    this.dataForm.closeFileList.push({
                        testId: this.testId,
                        recFileType: '2',
                        fileName: this.extractFileName(res.body.fileName),
                        filePath: res.body.fileSavePath,
                        fileUri: res.body.fileUri
                    });
                } else {
                    this.$message.info('文件上传失败')
                }
            })
        },
        extractFileName(fileName) {
            let index = fileName.lastIndexOf(".");
            return fileName.substr(0, index)
        },
        dataFormSubmit() {
            this.dataForm.testId = this.testId
            let _url = "/business/testrecordfile/save";
            this.$http({
                url: this.$store.state.httpUrl + _url,
                method: "post",
                data: this.$http.adornData(this.dataForm)
            }).then(({ data }) => {
                if (data && data.resultCode === 200) {
                    this.$message({
                        message: "操作成功",
                        type: "success",
                        duration: 1500,
                        onClose: () => {
                            this.visible = false;
                        }
                    });
                }
            });
        }
    }
};
</script>

<style scoped="scoped">
.selItemInput.descAreaLg {
    width: 718px !important;
}

.selItemInput.descAreaLg1 {
    width: 918px !important;
}

.addImgBox {
    width: 180px;
    height: 180px;
    display: inline-block;
    vertical-align: middle;
    margin-right: 10px;
}

.upload-demo /deep/ .el-upload-dragger {
    width: 260px;
    height: 185px;
}

.upload-demo /deep/ .el-upload-dragger .el-icon-upload {
    font-size: 40px;
    margin: 0 0;
    line-height: 40px;
    padding-top: 45px
}

.upload-demo /deep/ .el-upload-dragger .el-upload__text {
    line-height: 20px;
}

.sp-input /deep/ .el-input__inner {
    width: 100% !important;
}

.item::v-deep .el-form-item__label {
    color: #00428e !important;
    margin-right: 10px !important;
    font-weight: bold !important;
    background-color: #E1F1FF;
    white-space: nowrap !important;
    border-right: 1px solid #dadada;
}

::v-deep .el-form-item {
    display: flex !important;
    align-items: stretch !important;
    margin-bottom: 0px !important;
}

.fileItem::v-deep .el-form-item__label {
    text-align: left;
    padding-left: 20px;
    color: #00428e !important;
    margin: 5px 0px 10px 0px !important;
    ;
    font-weight: bold !important;
    ;
    width: 915px !important;
    background-color: #E1F1FF;
}

.row-form-item {
    margin-top: 10px;
}

.row-form-item .el-form-item {
    margin-right: 0;
}


.border-form .el-row.set_border {
    border-bottom: 1px solid #dadada;
    border-left: 1px solid #dadada;
    border-right: 1px solid #dadada;
}

.border-form .el-row.set_border:first-child {
    border-top: 1px solid #dadada;
}

.border-form .el-row.set_border .col-set_border {
    border-right: 1px solid #dadada;
}
</style>
